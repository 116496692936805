import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Card, Form, Alert } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import ConfirmationForm from "../containers/ConfirmationForm";
import { useFormFields } from "../libs/hooksLib";
import styles from "./ResetPassword.module.css";

export default function ResetPassword() {
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isDisplayConfirmationCodePage, setDisplayConfirmationCodePage] = useState(false);

  function validateCodeForm() {
    return fields.email.length > 0;
  }
  function validateResetForm() {
    return (
      fields.code.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSendCodeClick(event) {
    event.preventDefault();

    setIsSendingCode(true);
    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true);
    } catch (e) {
      let errorMsg = e.message;
      if (e.code === 'InvalidParameterException') {
        setDisplayConfirmationCodePage(true);
        errorMsg += '.\nIt will jump to Confrimation Code page and please check your email for the code.';
      }
      alert(errorMsg)
      setIsSendingCode(false);
    }
  };

  async function handleConfirmClick(event) {
    event.preventDefault();

    setIsConfirming(true);
    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      setConfirmed(true);
    } catch (e) {
      alert(e.message);
      setIsConfirming(false);
    }
  };

  function renderRequestCodeForm() {
    return (
      <form onSubmit={handleSendCodeClick}>
        <Card bg="light">
          <Card.Body>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                size="lg"
                value={fields.email}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <div className="d-grid">
              <LoaderButton
                type="submit"
                size="lg"
                loadingText="Sending…"
                text="Send Confirmation"
                isLoading={isSendingCode}
                disabled={!validateCodeForm()}
                variant="warning"
              />
            </div>
          </Card.Body>
        </Card>
      </form>
    );
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmClick}>
        <Card bg="light">
          <Card.Body>
            <Alert variant="warning">
              Please keep this page open. Copy the 6-digit code that you will receive by email and enter it into the Confirmation Code box below.
            </Alert>
            <Form.Group className="mb-3" controlId="code">
              <Form.Label>Confirmation Code</Form.Label>
              <Form.Control
                autoFocus
                type="tel"
                size="lg"
                value={fields.code}
                onChange={handleFieldChange}
              />
              <Form.Text muted>{`Please check your email (${fields.email}) for the confirmation code.`}</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="password">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                size="lg"
                value={fields.password}
                onChange={handleFieldChange}
              />
              <Form.Text muted>Needs to be 8 characters long with at least one number, uppercase letter, and lowercase letter each.</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                size="lg"
                onChange={handleFieldChange}
                value={fields.confirmPassword}
              />
            </Form.Group>
            <div className="d-grid">
              <LoaderButton
                block
                type="submit"
                size="lg"
                text="Confirm"
                loadingText="Confirm…"
                isLoading={isConfirming}
                disabled={!validateResetForm()}
              />
            </div>
          </Card.Body>
        </Card>
      </form >
    );
  }
  function renderSuccessMessage() {
    return (
      <div className={styles.success}>
        <Alert variant="success">
          <i className={`${styles.iconOk} bi bi-check2-circle`} />
          <p>Your password has been reset.</p>
          <div className="d-grid">
            <LoaderButton
              href="/login"
              type="button"
              size="lg"
            >
              Back to login screen
            </LoaderButton>
          </div>
        </Alert>
      </div>
    );
  }

  return (
    <div className={styles.resetPassword}>
      {isDisplayConfirmationCodePage ? <ConfirmationForm email={fields.email} /> :
        !codeSent
          ? renderRequestCodeForm()
          : !confirmed
            ? renderConfirmationForm()
            : renderSuccessMessage()}

    </div>
  );
}
