import React, { useState } from "react";
import { Button, Tabs, Tab, InputGroup, Form, DropdownButton, Dropdown } from "react-bootstrap";
import TextInputField from "../components/TextInputField";
import DropdownWithItem from "../components/DropdownWithItem";
import { ReactComponent as CogSvg } from "../assets/icons/tabs/cog.svg";
import { ReactComponent as IceSvg } from "../assets/icons/tabs/ice.svg";
import { ReactComponent as ElecSvg } from "../assets/icons/tabs/elec.svg";
import { RUN_TYPE, VEHICLE_TYPE, DEFAULT_VEHICLE_TYPE } from "../AppConfig";
import styles from "./InputPanel.module.css";

export default function InputPanel(props) {
    // the points to differenciate between SouthIsland and NorthIsland
    const cookStraitPointX = 174.5;
    const cookStraitPointY = -40.3;

    const GenServiceTime = {
        FIELD: 'genServiceTime',
        DEFAULT: '5.0',
        MIN: 0,
        MAX: 60,
        DECIMAL: 1,
        LABEL: 'Service Time'
    }
    const GenAverageVolume = {
        FIELD: 'genAverageVolume',
        DEFAULT: '0.100',
        MIN: 0,
        MAX: 20,
        DECIMAL: 3,
        LABEL: 'Average volume per pickup/delivery'
    }
    const GenAverageWeight = {
        FIELD: 'genAverageWeight',
        DEFAULT: '1.0',
        MIN: 0,
        MAX: 50000,
        DECIMAL: 1,
        LABEL: 'Average weight per pickup/delivery',
        POPUP: 'Enter the average weight of each package you will pick-up or deliver (the accumulated mass is used in the energy calculation for EV).'
    }
    const GenCrewCost = {
        FIELD: 'genCrewCost',
        DEFAULT: '60.00',
        MIN: 0,
        MAX: 500,
        DECIMAL: 2,
        LABEL: 'Vehicle Crew cost per vehicle per hour'
    }
    const IceNumberVehicles = {
        FIELD: 'iceNumberVehicles',
        DEFAULT: '5',
        MIN: 1,
        MAX: 10,
        DECIMAL: 0,
        LABEL: 'Existing number of Vehicles'
    }
    const IceMaxVolume = {
        FIELD: 'iceMaxVolume',
        DEFAULT: '10',
        MIN: 1,
        MAX: 100,
        DECIMAL: 0,
        LABEL: 'Maximum volume capacity of vehicle'
    }
    const IceMaxWeight = {
        FIELD: 'iceMaxWeight',
        DEFAULT: 1000,
        MIN: 100,
        MAX: 50000,
        DECIMAL: 0,
        LABEL: 'Maximum weight capacity of vehicle'
    }
    const IceFuelCost = {
        FIELD: 'iceFuelCost',
        DEFAULT: '0.20',
        MIN: 0,
        MAX: 20,
        DECIMAL: 2,
        LABEL: 'Current fuel cost per km'
    }
    const IceMaintenanceCost = {
        FIELD: 'iceMaintenanceCost',
        DEFAULT: '50.00',
        MIN: 0,
        MAX: 100,
        DECIMAL: 2,
        LABEL: 'Current maintenance cost per 1,000 km'
    }
    const IceRucCost = {
        FIELD: 'iceRucCost',
        DEFAULT: '100.00',
        MIN: 0,
        MAX: 1000,
        DECIMAL: 2,
        LABEL: 'Current RUC cost per 1,000 km'
    }

    const EvMaxNumber = {
        FIELD: 'evMaxNumber',
        DEFAULT: '5',
        MIN: 1,
        MAX: 10,
        DECIMAL: 0,
        LABEL: 'Maximum number of Vehicles'
    }

    const EvBatteryCapacity = {
        FIELD: 'evBatteryCapacity',
        DEFAULT: '150',
        MIN: 0,
        MAX: 500,
        DECIMAL: 0,
        LABEL: 'Battery Capacity'
    }

    const EvMaxVolume = {
        FIELD: 'evMaxVolume',
        DEFAULT: '10',
        MIN: 1,
        MAX: 100,
        DECIMAL: 0,
        LABEL: 'Maximum volume capacity of vehicle'
    }
    const EvMaxWeight = {
        FIELD: 'evMaxWeight',
        DEFAULT: 1000,
        MIN: 100,
        MAX: 50000,
        DECIMAL: 0,
        LABEL: 'Maximum weight capacity of vehicle'
    }
    const EvUnladenWeight = {
        FIELD: 'evUnladenWeight',
        DEFAULT: 2600,
        MIN: 1,
        MAX: 30000,
        DECIMAL: 0,
        LABEL: 'Vehicle Weight (unladen)',
        POPUP: 'Optionally you may enter the actual kerbside weight of your vehicle where known.'
    }

    const EvStaticLoad = {
        FIELD: 'evStaticLoad',
        DEFAULT: 80,
        MIN: 0,
        MAX: 10000,
        DECIMAL: 0,
        LABEL: 'Static Load (driver, passengers & gear)',
        POPUP: 'Optionally you may enter any additional fixed load that exists for the entire journey. Do not include here any variable weights associated with your pickup or delivery packages.'
    }

    const EvElecPrice = {
        FIELD: 'evElecPrice',
        DEFAULT: '0.29',
        MIN: 0,
        MAX: 1,
        DECIMAL: 2,
        LABEL: 'Current Electricity Price per kWh'
    }
    const EvRucCost = {
        FIELD: 'evRucCost',
        DEFAULT: '0.00',
        MIN: 0,
        MAX: 1000,
        DECIMAL: 2,
        LABEL: 'Current RUC cost per 1,000 km'
    }

    const [activeTabKey, setActiveTabKey] = useState('general');
    const [genRunTypeKey, setGenRunTypeKey] = useState('0');
    const [genRunTypeValue, setGenRunTypeValue] = useState('Delivery');
    const [genStartTime, setGenStartTime] = useState('09:00');
    const [genEndTime, setGenEndTime] = useState('17:00');
    const [genServiceTime, setGenServiceTime] = useState(GenServiceTime.DEFAULT);
    const [genAverageVolume, setGenAverageVolume] = useState(GenAverageVolume.DEFAULT);
    const [genAverageWeight, setGenAverageWeight] = useState(GenAverageWeight.DEFAULT);
    const [genCrewCost, setGenCrewCost] = useState(GenCrewCost.DEFAULT);
    const [iceTypeKey, setIceTypeKey] = useState(0);
    const [iceTypeValue, setIceTypeValue] = useState(DEFAULT_VEHICLE_TYPE);
    const [iceNumberVehicles, setIceNumberVehicles] = useState(IceNumberVehicles.DEFAULT);
    const [iceMaxVolume, setIceMaxVolume] = useState(IceMaxVolume.DEFAULT);
    const [iceMaxWeight, setIceMaxWeight] = useState(IceMaxWeight.DEFAULT.toLocaleString());
    const [iceFuelCost, setIceFuelCost] = useState(IceFuelCost.DEFAULT);
    const [iceMaintenanceCost, setIceMaintenanceCost] = useState(IceMaintenanceCost.DEFAULT);
    const [iceRucCost, setIceRucCost] = useState(IceRucCost.DEFAULT.toLocaleString());
    const [evTypeKey, setEvTypeKey] = useState(0);
    const [evTypeValue, setEvTypeValue] = useState(DEFAULT_VEHICLE_TYPE);
    const [evBatteryCapacity, setEvBatteryCapacity] = useState(EvBatteryCapacity.DEFAULT);
    const [evMaxNumber, setEvMaxNumber] = useState(EvMaxNumber.DEFAULT);
    const [evMaxVolume, setEvMaxVolume] = useState(EvMaxVolume.DEFAULT);
    const [evMaxWeight, setEvMaxWeight] = useState(EvMaxWeight.DEFAULT.toLocaleString());
    const [evUnladenWeight, setEvUnladenWeight] = useState(EvUnladenWeight.DEFAULT.toLocaleString());
    const [evStaticLoad, setEvStaticLoad] = useState(EvStaticLoad.DEFAULT.toLocaleString());
    const [evElecPrice, setEvElecPrice] = useState(EvElecPrice.DEFAULT);
    const [evRucCost, setEvRucCost] = useState(EvRucCost.DEFAULT.toLocaleString());
    //             //Error state
    const [genServiceTimeError, setGenServiceTimeError] = useState(null);
    const [genAverageVolumeError, setGenAverageVolumeError] = useState(null);
    const [genAverageWeightError, setGenAverageWeightError] = useState(null);
    const [genCrewCostError, setGenCrewCostError] = useState(null);

    const [iceFuelCostError, setIceFuelCostError] = useState(null);
    const [iceMaintenanceCostError, setIceMaintenanceCostError] = useState(null);
    const [iceRucCostError, setIceRucCostError] = useState(null);
    const [iceNumberVehiclesError, setIceNumberVehiclesError] = useState(null);
    const [iceMaxVolumeError, setIceMaxVolumeError] = useState(null);
    const [iceMaxWeightError, setIceMaxWeightError] = useState(null);

    const [evBatteryCapacityError, setEvBatteryCapacityError] = useState(null);
    const [evMaxNumberError, setEvMaxNumberError] = useState(null);
    const [evMaxVolumeError, setEvMaxVolumeError] = useState(null);
    const [evMaxWeightError, setEvMaxWeightError] = useState(null);
    const [evUnladenWeightError, setEvUnladenWeightError] = useState(null);
    const [evStaticLoadError, setEvStaticLoadError] = useState(null);
    const [evElecPriceError, setEvElecPriceError] = useState(null);
    const [evRucCostError, setEvRucCostError] = useState(null);
    // const [haveInputError, setHaveInputError] = useState(false);

    const unitTime = <InputGroup.Text className={styles.unit}>mins</InputGroup.Text>;
    const unitVolume = <InputGroup.Text className={styles.unit}>m<sup>3</sup></InputGroup.Text>;
    const unitWeight = <InputGroup.Text className={styles.unit}>Kg</InputGroup.Text>;
    const unitDollar = <InputGroup.Text className={styles.unit}>$</InputGroup.Text>;
    const unitEnergy = <InputGroup.Text className={styles.unit}>kWh</InputGroup.Text>

    function handleGenRunTypeOnSelect(evtKey, evt) {
        setGenRunTypeKey(evtKey);
        setGenRunTypeValue(evt.target.textContent);
    }

    function handleGenStartTimeOnChange(event) {
        setGenStartTime(event.target.value);
    }

    function handleGenEndTimeOnChange(event) {
        setGenEndTime(event.target.value);
    }

    function removeComma(input, decimal) {
        return (input !== "" && input !== ".") ? parseFloat(input.replace(/,/g, "")).toFixed(decimal) : input;
    }

    function convertToVehicleMass(typeKey) {
        for (const vehicleItem of VEHICLE_TYPE) {
            if (vehicleItem["typeKey"] === typeKey) {
                return vehicleItem["typeMass"];
            }
        }
        return null;
    }

    function handleEvTypeOnSelect(evtKey, evt) {
        setEvBatteryCapacityError(null);
        setEvTypeKey(evtKey);
        setEvTypeValue(evt.target.textContent);
        setEvUnladenWeight(convertToVehicleMass(evtKey));
    }

    function handleIceTypeOnSelect(evtKey, evt) {
        setIceTypeKey(evtKey);
        setIceTypeValue(evt.target.textContent);
    }

    function handleNextTabOnClick() {
        const map = {
            'general': 'internal_combustion',
            'internal_combustion': 'electric'
        }
        setActiveTabKey(map[activeTabKey]);
    }

    function handleTabSelect(activeTabKey) {
        setActiveTabKey(activeTabKey);
    }

    function FormatRequest(startPoint, endPoint, points) {
        let startDepot = {
            "Address": startPoint?.Address ?? "",
            "X": startPoint?.X ?? 0,
            "Y": startPoint?.Y ?? 0
        };
        let endDepot = {
            "Address": endPoint?.Address ?? "",
            "X": endPoint?.X ?? 0,
            "Y": endPoint?.Y ?? 0
        }
        let iqofficeCallCount = props.iqofficeCallCount;
        let cognitoUser = props.cognitoUser;


        return {
            "RouteData": {
                "Options": {
                    "StartDepot": startDepot,
                    "EndDepot": endDepot,
                },
                "Points":
                    points.map((point) => {
                        return {
                            "X": point.X,
                            "Y": point.Y,
                            "Address": point.Address,
                            "Id": point.Id ?? "",
                            "Details": point.Details ?? ""
                        }
                    }),
            },
            "OptimisationData": {
                "User": cognitoUser,
                "Iqoffice_call_count": iqofficeCallCount
            },
            "GeneralInputs": {
                "RunType": genRunTypeKey,
                "StartTime": "2018-01-01T" + genStartTime,
                "EndTime": "2018-01-01T" + genEndTime,
                "ServiceTime": genServiceTime,
                "AverageVolume": genAverageVolume,
                "AverageWeight": removeComma(genAverageWeight, GenAverageWeight.DECIMAL),
                "CrewCost": genCrewCost,
            },
            "ICEInputs": {
                "VehicleType": iceTypeKey,
                "NumberOfVehicles": iceNumberVehicles,
                "MaxVolume": iceMaxVolume,
                "MaxWeight": removeComma(iceMaxWeight, IceMaxWeight.DECIMAL),
                "FuelCostPerKm": iceFuelCost,
                "MaintenanceCostPerKm": iceMaintenanceCost,
                "RucCostPerkm": removeComma(iceRucCost, IceRucCost.DECIMAL)
            },
            "ElectricInputs": {
                "VehicleType": evTypeKey,
                "BatteryCapacity": evBatteryCapacity,
                "NumberOfVehicles": evMaxNumber,
                "MaxVolume": evMaxVolume,
                "MaxWeight": removeComma(evMaxWeight, EvMaxWeight.DECIMAL),
                "UnladenWeightOverride": removeComma(evUnladenWeight, EvUnladenWeight.DECIMAL),
                "StaticLoad": removeComma(evStaticLoad, EvStaticLoad.DECIMAL),
                "ElectricityPrice": evElecPrice,
                "RucCostPerkm": removeComma(evRucCost, EvRucCost.DECIMAL)
            }
        }
    }

    function checkExistAnyError() {
        return genServiceTimeError === 'error' ||
            genAverageVolumeError === 'error' ||
            genAverageWeightError === 'error' ||
            genCrewCostError === 'error' ||
            iceFuelCostError === 'error' ||
            iceMaintenanceCostError === 'error' ||
            iceRucCostError === 'error' ||
            iceNumberVehiclesError === 'error' ||
            iceMaxVolumeError === 'error' ||
            iceMaxWeightError === 'error' ||
            evBatteryCapacityError === 'error' ||
            evMaxNumberError === 'error' ||
            evMaxVolumeError === 'error' ||
            evMaxWeightError === 'error' ||
            evUnladenWeightError === 'error' ||
            evStaticLoadError === 'error' ||
            evElecPriceError === 'error' ||
            evRucCostError === 'error';
    }

    // /**
    //  * Onclick GenerateComparison button
    //  * @param {*} event input event
    //  */
    function handleGenerateComparisonOnClick(event) {
        if (checkExistAnyError()) {
            alert("There are some input errors.");
            event.preventDefault();
            return;
        }

        let startPoint = props.startPoint;
        let endPoint = props.endPoint;
        let points = props.points;
        let isNorthIsland = false;
        let isSouthIsland = false;
        //Start Point
        if ((props.startPoint?.Address !== undefined) && (props.startPoint.Address !== "")) {
            if ((props.startPoint.Y < cookStraitPointY) && (props.startPoint.X < cookStraitPointX)) {
                isSouthIsland = true;
            } else {
                isNorthIsland = true;
            }
            startPoint = { X: props.startPoint.X, Y: props.startPoint.Y, Address: props.startPoint.Address };
        } else {
            alert("The start address need to be filled in.");
            return;
        }
        //End Point
        if ((props.endPoint?.Address !== undefined) && (props.endPoint.Address !== "")) {
            if ((props.endPoint.Y < cookStraitPointY) && (props.endPoint.X < cookStraitPointX)) {
                isSouthIsland = true;
            } else {
                isNorthIsland = true;
            }
            endPoint = { X: props.endPoint.X, Y: props.endPoint.Y, Address: props.endPoint.Address };
        } else {
            alert("The end address need to be filled in.");
            return;
        }
        //All Points
        points = props.points.map(point => {
            if ((point.Y < cookStraitPointY) && (point.X < cookStraitPointX)) {
                isSouthIsland = true;
            } else {
                isNorthIsland = true;
            }
            return { X: point.X, Y: point.Y, Address: point.Address, Id: point.id, Details: point.details };
        });
        if (isNorthIsland && isSouthIsland) {
            //Error different island
            alert("The addresses need to be on the same island.");
            return;
        }
        if (genStartTime > genEndTime) {
            alert("The departure time needs to be earlier than the latest delivery/pickup time.");
            return;
        }
        let inputsData = {
            headers: {
                'Content-Type': 'application/json'
            },
            body: FormatRequest(startPoint, endPoint, points)
        }
        props.startOptimisation(inputsData);

    }
    return (
        <React.Fragment>
            <Tabs fill className={styles.tabs} id="general" defaultActiveKey="general" activeKey={activeTabKey} onSelect={handleTabSelect}>
                <Tab eventKey="general" title={<span className={styles.tabHead}><span className={styles.tabHeadSvg}><CogSvg /></span>General</span>}>
                    <InputGroup className={`${styles.inputGroupCustom} mb-3`}>
                        <InputGroup.Text className={styles.inputGroupAddonCustom}>Run type</InputGroup.Text>
                        <DropdownButton id={styles.genRunTypeId} title={genRunTypeValue} key={genRunTypeKey} variant="outline-secondary"
                            onSelect={handleGenRunTypeOnSelect} >
                            <Dropdown.Item eventKey="0">{RUN_TYPE[0]}</Dropdown.Item>
                            <Dropdown.Item eventKey="1">{RUN_TYPE[1]}</Dropdown.Item>
                        </DropdownButton>
                    </InputGroup >
                    <InputGroup className={`${styles.inputGroupCustom} mb-3`}>
                        <InputGroup.Text className={styles.inputGroupAddonCustom}>Departure Time</InputGroup.Text>
                        <Form.Control type="time" value={genStartTime} onChange={handleGenStartTimeOnChange} />
                    </InputGroup>
                    <InputGroup className={`${styles.inputGroupCustom} mb-3`}>
                        <InputGroup.Text className={styles.inputGroupAddonCustom}>Latest Delivery/Pickup</InputGroup.Text>
                        <Form.Control type="time" value={genEndTime} onChange={handleGenEndTimeOnChange} />
                    </InputGroup>
                    <TextInputField inputField={GenServiceTime} inputValue={genServiceTime} setFieldValue={setGenServiceTime} fieldError={genServiceTimeError} setFieldError={setGenServiceTimeError} unit={unitTime} />
                    <TextInputField inputField={GenAverageVolume} inputValue={genAverageVolume} setFieldValue={setGenAverageVolume} fieldError={genAverageVolumeError} setFieldError={setGenAverageVolumeError} unit={unitVolume} />
                    <TextInputField inputField={GenAverageWeight} inputValue={genAverageWeight} setFieldValue={setGenAverageWeight} fieldError={genAverageWeightError} setFieldError={setGenAverageWeightError} unit={unitWeight} />
                    <TextInputField inputField={GenCrewCost} inputValue={genCrewCost} setFieldValue={setGenCrewCost} fieldError={genCrewCostError} setFieldError={setGenCrewCostError} unit={unitDollar} />
                    <Form.Group className={styles.textCenter}>
                        <Button variant="primary" id="generalNext" onClick={handleNextTabOnClick}>Next</Button>
                    </Form.Group>
                </Tab >
                <Tab eventKey="internal_combustion" title={<span className={styles.tabHead}><span className={styles.tabHeadSvg}><IceSvg /></span>Internal Combustion</span>}>
                    <Form.Group className={styles.textCenter}>
                        <InputGroup className={`${styles.inputGroupCustom} mb-3`} justified="true">
                            <InputGroup.Text className={styles.inputGroupAddonCustom}>Vehicle Type</InputGroup.Text>
                            <DropdownWithItem id={styles.iceTypeId} title={iceTypeValue} typeKey={iceTypeKey} data={VEHICLE_TYPE} handleTypeOnSelect={handleIceTypeOnSelect} />
                        </InputGroup>
                    </Form.Group>
                    <TextInputField inputField={IceNumberVehicles} inputValue={iceNumberVehicles} setFieldValue={setIceNumberVehicles} fieldError={iceNumberVehiclesError} setFieldError={setIceNumberVehiclesError} />
                    <TextInputField inputField={IceMaxVolume} inputValue={iceMaxVolume} setFieldValue={setIceMaxVolume} fieldError={iceMaxVolumeError} setFieldError={setIceMaxVolumeError} unit={unitVolume} />
                    <TextInputField inputField={IceMaxWeight} inputValue={iceMaxWeight} setFieldValue={setIceMaxWeight} fieldError={iceMaxWeightError} setFieldError={setIceMaxWeightError} unit={unitWeight} />
                    <TextInputField inputField={IceFuelCost} inputValue={iceFuelCost} setFieldValue={setIceFuelCost} fieldError={iceFuelCostError} setFieldError={setIceFuelCostError} unit={unitDollar} />
                    <TextInputField inputField={IceMaintenanceCost} inputValue={iceMaintenanceCost} setFieldValue={setIceMaintenanceCost} fieldError={iceMaintenanceCostError} setFieldError={setIceMaintenanceCostError} unit={unitDollar} />
                    <TextInputField inputField={IceRucCost} inputValue={iceRucCost} setFieldValue={setIceRucCost} fieldError={iceRucCostError} setFieldError={setIceRucCostError} unit={unitDollar} />
                    <Form.Group className={styles.textCenter}>
                        <Button variant="primary" id="iceNext" onClick={handleNextTabOnClick}>Next</Button>
                    </Form.Group>
                </Tab>
                <Tab eventKey="electric" title={<span className={styles.tabHead}><span className={styles.tabHeadSvg}><ElecSvg /></span>Electric</span>}>
                    <Form.Group className={styles.textCenter}>
                        <InputGroup className={`${styles.inputGroupCustom} mb-3`} justified="true">
                            <InputGroup.Text className={styles.inputGroupAddonCustom}>Vehicle Type</InputGroup.Text>
                            <DropdownWithItem id={styles.evTypeId} title={evTypeValue} key={evTypeKey} data={VEHICLE_TYPE} handleTypeOnSelect={handleEvTypeOnSelect} />
                        </InputGroup>
                    </Form.Group>
                    <TextInputField inputField={EvUnladenWeight} inputValue={evUnladenWeight} setFieldValue={setEvUnladenWeight} setFieldError={setEvUnladenWeightError} unit={unitWeight} />
                    <div className="mb-5"></div>
                    <TextInputField inputField={EvStaticLoad} inputValue={evStaticLoad} setFieldValue={setEvStaticLoad} fieldError={evStaticLoadError} setFieldError={setEvStaticLoadError} unit={unitWeight} />
                    <TextInputField inputField={EvMaxNumber} inputValue={evMaxNumber} setFieldValue={setEvMaxNumber} fieldError={evMaxNumberError} setFieldError={setEvMaxNumberError} />
                    <TextInputField inputField={EvBatteryCapacity} inputValue={evBatteryCapacity} setFieldValue={setEvBatteryCapacity} fieldError={evBatteryCapacityError} setFieldError={setEvBatteryCapacityError} unit={unitEnergy} />
                    <TextInputField inputField={EvMaxVolume} inputValue={evMaxVolume} setFieldValue={setEvMaxVolume} fieldError={evMaxVolumeError} setFieldError={setEvMaxVolumeError} unit={unitVolume} />
                    <TextInputField inputField={EvMaxWeight} inputValue={evMaxWeight} setFieldValue={setEvMaxWeight} fieldError={evMaxWeightError} setFieldError={setEvMaxWeightError} unit={unitWeight} />
                    <TextInputField inputField={EvElecPrice} inputValue={evElecPrice} setFieldValue={setEvElecPrice} fieldError={evElecPriceError} setFieldError={setEvElecPriceError} unit={unitDollar} />
                    <TextInputField inputField={EvRucCost} inputValue={evRucCost} setFieldValue={setEvRucCost} fieldError={evRucCostError} setFieldError={setEvRucCostError} unit={unitDollar} />
                    <Form.Group className={styles.textCenter}>
                        <Button variant="primary" id="getComparison" onClick={handleGenerateComparisonOnClick}>Generate Comparison</Button>
                    </Form.Group>
                </Tab>
            </Tabs>

        </React.Fragment>
    )
}
