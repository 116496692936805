import React from 'react';
import { Accordion } from "react-bootstrap";
import styles from "./PanelHeader.module.css";


export default function PanelHeader(props) {

    return (
        <Accordion.Header className={styles.panelHeading}>
            <div className={styles.iconLeft}>{props.children}</div>
            {props.title}
            <div className={styles.iconRight}>{props.panelActiveKey === props.eventKey ? <i className={"bi bi-chevron-up"} /> : <i className={"bi bi-chevron-down"} />}</div>
        </Accordion.Header>
    )
}
