import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Card, Form, Alert } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import ConfirmationForm from "../containers/ConfirmationForm";
import styles from "./Signup.module.css";

export default function Signup(props) {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    company: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
    EmailConfirmationResend: "",
    CodeConfirmationResend: "",
    signuperror: "",
    confirmusererror: "",
  });
  const [errorCode, setErrorCode] = useState("");
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplayConfirmationCode, setDisplayConfirmationCode] = useState(false);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.company.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    fields.signuperror = "";

    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
        clientMetadata:
        {
          "signupcode": document.location.pathname,
          "company": fields.company
        },
        attributes:
        {
          "email": fields.email,
          "custom:signupcode": document.location.pathname,
          "custom:company": fields.company
        }
      });
      setIsLoading(false);
      setNewUser(newUser);

    } catch (e) {
      setErrorCode(e.code);
      fields.signuperror = e.message;
      setIsLoading(false);
    }
  }

  function handleEnterSignupCodeOnClick() {
    setDisplayConfirmationCode(true);
  }

  function renderSignupError() {
    return (
      <Alert variant="danger">
        <Alert.Heading as="h5">Error signing up</Alert.Heading>
        {errorCode === "UsernameExistsException" ?
          <p>
            An account with the given email address already exists. Please do one of the following:
            <ul>
              <li>If you have forgotten your password, please click <Alert.Link id="login" href="/login/reset">here</Alert.Link></li>
              <li>If you need a new confirmation code to verify your account, please click <Alert.Link href="#" id="enterSignupCode" onClick={handleEnterSignupCodeOnClick}>here</Alert.Link> </li>
            </ul>
          </p>
          : <p>{fields.signuperror}</p>}
      </Alert>
    );
  }

  function renderForm() {
    return (
      <div className={styles.signup}>
        <form onSubmit={handleSubmit}>
          <Card bg="light">
            <Card.Body>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  autoFocus
                  type="company"
                  size="lg"
                  value={fields.company}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="email" className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autoFocus
                  type="email"
                  size="lg"
                  value={fields.email}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="password" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  size="lg"
                  value={fields.password}
                  onChange={handleFieldChange}
                />
                <Form.Text muted>Must be 8 characters long with at least one number, uppercase letter, and lowercase letter each.</Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  size="lg"
                  onChange={handleFieldChange}
                  value={fields.confirmPassword}
                />
              </Form.Group>

              {fields.signuperror.length > 0 ? renderSignupError() : null}
              <div className="d-grid">
                <LoaderButton
                  type="submit"
                  size="lg"
                  isLoading={isLoading}
                  disabled={!validateForm()}
                >
                  Sign up
                </LoaderButton>
              </div>
            </Card.Body>
          </Card>
        </form>
      </div >
    );
  }

  return (
    <>
      {
        newUser === null ? (isDisplayConfirmationCode ? <ConfirmationForm email={fields.email} password={fields.password} props={props} /> : renderForm()) : <ConfirmationForm email={fields.email} password={fields.password} props={props} />
      }
    </>
  );
}
