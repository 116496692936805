import React from "react";
import Critchlow_Logo_h64 from '../assets/logos/CRTGEO_logo_h64.png';
import SMFLogo from "../assets/logos/SMF_logo.png";
import styles from './PrintHeader.module.css';

export default function PrintHeader() {
    return (

        <div className={`hidden printshow ${styles.printLogo}`}>
            <img src={Critchlow_Logo_h64} width="152" height="32" className={`${styles.critchlowLogo} ${styles.textAlignLeft}`} alt="Critchlow logo" />
            <img src={SMFLogo} width="234" height="50" className={styles.textAlignRight} alt="SMF logo" />
            <br/>
            <br/>
            <br/>
        </div>


    )
}