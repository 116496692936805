import React from "react";
import styles from "./CountDown.module.css";

export default function CountDown({ inputSec = 0, processStatus = 0 }) {
    const [seconds, setSeconds] = React.useState(parseInt(inputSec));
    const [reset, setReset] = React.useState(false);
    React.useEffect(() => {
        const tick = () => {
            if (seconds === 0) {
                return;
            } else {
                setSeconds(seconds - 1);
            }
        };
        if (processStatus > 0 && !reset) {
            setReset(true);
            setSeconds(inputSec);
        }
        let timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    }, [seconds, processStatus, inputSec, reset]);

    return (
        <div className={`${styles.textCenter} ${styles.fontWhite}`}>
            {inputSec > 0 ?
                <b>
                    {processStatus === 0
                        ? (seconds > Math.floor(inputSec * 2 / 3)
                            ? 'SwitchMyFleet is calculating optimised ICE route(s) now.'
                            : 'SwitchMyFleet is calculating optimised EV route(s) now.')
                        : 'SwitchMyFleet is optimising energy, time and distance.'}
                    <br />
                </b>
                : null}
            {seconds === 0 ? "Working..." : `${seconds.toString().padStart(2, "0")} seconds`}
        </div>
    );
}

