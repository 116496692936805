import React, { useState } from 'react';
import DataGrid, { textEditor } from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import styles from "./DeliveryAddressGrid.module.css";


const addressColumn = { key: "address", name: "Address", width: "100%", editor: textEditor };

export default function DeliveryAddressGrid(props) {

    const [rows, setRows] = useState(props.deliveryAddressGridList);
    const [top, setTop] = useState(0);

    React.useEffect(() => {
        setRows(props.deliveryAddressGridList);
        const elem = document.querySelectorAll('[role="grid"]')[0];

        function handlePaste(e) {
            console.debug('handlePaste Called');
            e.preventDefault();
            const newRows = [];
            const pastedText = e.clipboardData.getData('text/plain');
            var pastedRow = pastedText.replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/mg, function (match, p1) {
                // This function runs for each cell with multi lined text.
                // Replace any double double-quotes with a single double-quote
                // Replace all new lines with spaces.
                return p1.replace(/""/g, '"').replace(/\r\n|\n\r|\n|\r/g, ' ');
            }).split(/\r\n|\n\r|\n|\r/g);// Split each line into rows
            console.debug('pastedRow', pastedRow);

            pastedRow.forEach((row) => {
                //Split into different cells
                const pastedRowSplit = row.split(/\r\n|\n|\r/).map(line => line.split('\t'));
                pastedRowSplit.forEach((rowSplit) => {
                    const rowData = {};
                    if (rowSplit[0] !== undefined && rowSplit[0].length > 0) {
                        rowData[addressColumn.key] = rowSplit[0];
                        newRows.push(rowData);
                    } else {
                        // console.log("Deleted pasted empty row:" & rowData);
                    }
                });
            });
            console.debug('newRows', newRows);
            const tempRows = rows.slice();
            for (let i = 0; i < newRows.length; i++) {
                if (top + i < tempRows.length) {
                    tempRows[top + i] = { ...tempRows[top + i], ...newRows[i] };
                } else {
                    alert("You have exceed the limit of " + props.numberOfAddressLimitation + " Delivery Addresses.\nAny address(es) beyond " + props.numberOfAddressLimitation + " has been removed and will not be included in the optimisation.");
                    break;
                }
            }
            props.refreshRowsCallBack(tempRows);
            setRows(tempRows);
        }
        elem.addEventListener('paste', handlePaste);
        return () => {
            elem.removeEventListener('paste', handlePaste);
        };
    }, [props, rows, top]);


    function onRowsChange(rows) {
        setRows(rows);
        props.refreshRowsCallBack(rows);
    }
    function rowKeyGetter(row) {
        return row.id;
    }

    function setSelection(args) {
        setTop(args.row.id);
    };

    function handleCopy() {
        if (window.isSecureContext) {
            navigator.clipboard.writeText(window.getSelection());

        }
    }
    return <DataGrid
        className={styles.gridContainer}
        rowKeyGetter={rowKeyGetter}
        columns={[addressColumn]}
        rows={rows}
        onRowsChange={onRowsChange}
        onCellClick={setSelection}
        onCopy={handleCopy}
    />;
}