import React from "react";
import { Marker, Tooltip } from "react-leaflet";
import styles from './MapMarker.module.css';
export default function MapMarker({ position, icon, color = '#ff00c3', text }) {
    return (
        <Marker position={[position.Y, position.X]}
            icon={icon}>
            <Tooltip direction="top" offset={[2, -6]} opacity={1} permanent>
                <span className={styles.markerSequence} style={{ color: `${color}` }}>{text}</span>
            </Tooltip>
        </Marker>
    );
}