import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ResetPassword from "./pages/ResetPassword";

export default function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AuthenticatedRoute />}>
        <Route path="/" element={<Home />} />
      </Route>
      <Route path="/" element={<UnauthenticatedRoute />}>
        <Route path="/login" element={<Login />} />
        <Route path="/signup/:signupcode" element={<Signup />} />
        <Route path="/login/reset" element={<ResetPassword />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}