import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { API, Auth } from "aws-amplify";
import { Polyline } from "react-leaflet";
import L from "leaflet";
import LocationEntry from "./LocationEntry";
import InputPanel from "./InputPanel";
import InputReport from "./InputReport";
import OutputPanel from "./OutputPanel";
import MapWrapper from "./MapWrapper";
import AddressListError from "./AddressListError";
import PrintHeader from "../components/PrintHeader";
import LoadingSpinner from "../components/LoadingSpinner";
import MarkerIcon from "../components/MarkerIcon";
import MapMarker from "../components/MapMarker";
import Tutorial from "../components/Tutorial";
import PanelHeader from "../components/PanelHeader";
import PrintButtonArea from "../components/PrintButtonArea";
import Ribbon from "../components/Ribbon";
import { useAppContext } from "../libs/contextLib";
import { ReactComponent as GraphsSvg } from "../assets/icons/panels/graphs.svg";
import { COLOR_LIST, ELECTRIC, INTERNAL_COMBUSTION, SMART_ROUTING_EV } from "../AppConfig";
import styles from "./SmartRouting.module.css";
delete L.Icon.Default.prototype._getIconUrl;

export default function SmartRouting() {
  const componentInputAndComparisonToPrint = useRef();
  const componentMapToPrint = useRef();

  const customIconList = [];
  COLOR_LIST.map((color) => { return customIconList.push(MarkerIcon(color, '#ffffff', '#ffffff')); })
  const startEndMarkerIcon = MarkerIcon('#ff00c3', '#ffffff', '#ffffff');
  const geocodeIcon = MarkerIcon('#ffffff', '#009bde');
  const unassignedIcon = MarkerIcon('#ffffff', '#e00000');

  const { runAccessTutorial } = useAppContext();
  //All New Zealand
  //    [-33.632985, -176.095241]
  //    [-47.622324, 163.744142]
  const defaultSouthWest = L.latLng(-46, 163);
  const defaultNorthEast = L.latLng(-38, 165);
  const defaultBounds = L.latLngBounds(defaultSouthWest, defaultNorthEast);
  //------------------------
  const RESPONSE_STATUS_IDLE = 'idle';
  const RESPONSE_STATUS_BLOCKED = 'blocked';
  //--------------------------
  const unifiedErrorMsg = 'An internal server error occurred. Please submit this error message to support@critchlow.co.nz for further investigation.';
  //Addresses list variables + result
  const [responseStart, setResponseStart] = useState({});
  const [geocodedAddresses, setGeocodedAddress] = React.useState({
    start: {},
    end: {},
    list: []
  });

  const [addressListError, setAddressListError] = React.useState([]);
  const [iceResults, setIceResults] = React.useState({
    Routes: [],
    Statistic: {},
    NotAssigned: []
  });
  const [evResults, setEvResults] = React.useState({
    Routes: [],
    Statistic: {},
    NotAssigned: []
  });
  const [currentTab, setCurrentTab] = React.useState('');
  const [iceErrorMsg, setIceErrorMsg] = React.useState('');
  const [evErrorMsg, setEvErrorMsg] = React.useState('');
  const [currentRountId, setCurrentRountId] = React.useState(-1);
  const [mapHeight, setMapHeight] = React.useState(window.innerHeight - 50);
  //Custom Display variables
  const [panelHeight, setPanelHeight] = React.useState(window.innerHeight - 208);
  //for panelgroup. 1 or 2 or 3
  const [panelActiveKey, setPanelActiveKey] = React.useState('1');
  //LocationEntry Loader
  const [showLoader, setShowLoader] = React.useState(false);
  const [processStatus, setProcessStatus] = React.useState(0);
  const [isGetResultLoopOn, setIsGetResultLoopOn] = useState(false);
  // idle blocked
  const [responseStatus, setResponseStatus] = React.useState(RESPONSE_STATUS_IDLE);
  const [isResponsed, setIsResponsed] = React.useState(false);
  const [locatiumTimeout, setLocatiumTimeout] = React.useState(0);
  const [inputsData, setInputsData] = React.useState({});
  const [isRun, setIsRun] = React.useState(false);
  const [cognitoUser, setCognitoUser] = React.useState('');

  useEffect(() => {
    setIsRun(runAccessTutorial);
  }, [runAccessTutorial]);

  useEffect(() => {
    async function getCognitoInfo() {
      await Auth.currentSession();
      const currentUserInfo = await Auth.currentUserInfo();
      const user = currentUserInfo.attributes['email'];
      const loginCount = currentUserInfo.attributes['custom:login_count'];
      setCognitoUser(user);
      setIsRun(loginCount === 'first' ? true : false);
    }
    getCognitoInfo();
  }, []);

  useEffect(() => {
    const updateDimensions = () => {
      //Resize map size
      let height = window.innerHeight - 50; //50 is for the navbars on the top
      let panelHeight = window.innerHeight - 208;
      setMapHeight(height);
      setPanelHeight(panelHeight);
    }
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  });

  //LocationEntry Loader Start and Stop
  function startLoader() {
    setShowLoader(true);
    //Clear previous optimisation result
    setCurrentTab('');
    setIceResults({
      Routes: [],
      Statistic: {},
      NotAssigned: []
    });
    setEvResults({
      Routes: [],
      Statistic: {},
      NotAssigned: []
    });
  }

  function stopTotal() {
    stopLoader();
    setIsGetResultLoopOn(false);
  }

  function stopLoader() {
    setShowLoader(false);
    setLocatiumTimeout(0);
    setProcessStatus(0);
  }

  function selectRoutes(tab, routeId) {
    setCurrentTab(tab);
    setCurrentRountId(routeId);
  }



  function setGeocodesCallback(geocodedStartAddress, geocodedEndAddress, geocodedList, addressListError) {
    setGeocodedAddress({
      start: geocodedStartAddress,
      end: geocodedEndAddress,
      list: geocodedList
    })
    setCurrentTab('');
    setIceResults({
      Routes: [],
      Statistic: {},
      NotAssigned: []
    });
    setEvResults({
      Routes: [],
      Statistic: {},
      NotAssigned: []
    });
    setIsResponsed(false);
    stopLoader();
    //if the address list is mepty, the Address List does not collapse. 
    if (addressListError.length === 0 && geocodedStartAddress.X !== undefined && geocodedEndAddress.X !== undefined) {
      setPanelActiveKey('2');
    }
  }

  function setAddressErrors(addressListError) {
    //set address errors
    //Reset old marker list
    setGeocodedAddress({
      start: {},
      end: {},
      list: []
    });
    setIceResults({
      Routes: [],
      Statistic: {},
      NotAssigned: []
    });
    setEvResults({
      Routes: [],
      Statistic: {},
      NotAssigned: []
    });
    setCurrentTab('');
    setAddressListError(addressListError);
    setIsResponsed(false);
  }

  function updateBounds(listAddresses) {
    // console.debug("updateBounds:" + list.length);
    if (listAddresses.length > 0) {
      return listAddresses.map((point, j) => {
        return [point.Y, point.X];
      });
    } else {
      return defaultBounds;
    }
  }

  /**
     * Onclick Generate Comparison button
     */
  useEffect(() => {
    function finalize(response) {
      console.log("Optimisation Error " + response?.ErrorMessage);
      setPanelActiveKey('2');
      stopLoader();
    }
    async function startPolling(responseStart) {
      if (responseStart.SuccessStartOptimisation) {
        setResponseStart(responseStart);
        setLocatiumTimeout(responseStart.LocatiumTimeout);
        setIsGetResultLoopOn(true);
      } else {
        showErrorMessage(responseStart.ErrorMessage);
      }
    }
    async function requestStartApi() {
      let responseStart;
      try {
        const responseJson = await API.post(SMART_ROUTING_EV, process.env.REACT_APP_OPTIMISATION_START, inputsData);

        if (responseJson === null) {
          responseStart = "responseJson.error null";
          console.error(responseStart);
          finalize(responseStart);
        } else {
          stopLoader();
          responseStart = responseJson;
          setLocatiumTimeout(responseStart.LocatiumTimeout);
          startLoader();
        }
        startPolling(responseStart);
      } catch (error) {
        if (error.ErrorMessage !== undefined) {
          alert("Error during the start of the optimisation.\r\nDetails: " + error.ErrorMessage);
        } else {
          alert("An error has occurred initiating this optimisation.\r\n" +
            "This could be due to the combination of number of destinations and distances involved being too great for the solution to handle within the allocated time.\r\n" +
            "Please try reducing the number of points or limit your destinations to a smaller geographic area.");
        }
        console.error(error);
        responseStart = error;
        finalize(responseStart);
      }
    }
    if (Object.keys(inputsData).length !== 0) {
      requestStartApi();
    }
  }, [inputsData]);

  /**
 * the action of pressing the button 'Generate Comparison'
 * @param {*} response 
 */
  useEffect(() => {
    function finalize(response) {
      console.log("Optimisation Error " + response?.ErrorMessage);
      setPanelActiveKey('2');
      stopLoader();
    }

    function generateComparison(response) {
      const limitedRouteNumber = 20;
      if (response !== undefined && response.Success) {
        let iceResults = response.ICERouting;
        let evResults = response.EVRouting;
        if (iceResults != null && evResults != null) {
          let iceResultsForSet = {};
          let evResultsForSet = {};

          let iceRoutes = iceResults.Routes;
          let evRoutes = evResults.Routes;
          if (iceRoutes != null && evRoutes != null) {
            if (iceRoutes.length > limitedRouteNumber) {
              alert("The number of routes is over the limit. Some points won't be visible on the Internal Combustion optimisation result.");
            }
            if (evRoutes.length > limitedRouteNumber) {
              alert("The number of routes is over the limit. Some points won't be visible on the Electric optimisation result.");
            }
            iceResultsForSet["Routes"] = iceRoutes.slice(0, limitedRouteNumber);
            evResultsForSet["Routes"] = evRoutes.slice(0, limitedRouteNumber);
            setCurrentTab(ELECTRIC);
          }
          let iceStatistic = iceResults.Statistic;
          let evStatistic = evResults.Statistic;
          if (iceStatistic != null && evStatistic !== null) {
            iceStatistic["amountOfRoutes"] = iceRoutes.length;
            evStatistic["amountOfRoutes"] = evRoutes.length;
            iceResultsForSet["Statistic"] = iceStatistic;
            evResultsForSet["Statistic"] = evStatistic;
            setPanelActiveKey('3');
          }
          iceResultsForSet["NotAssigned"] = iceResults.AddressesNotAssigned;
          evResultsForSet["NotAssigned"] = evResults.AddressesNotAssigned;
          setIceResults(iceResultsForSet);
          setEvResults(evResultsForSet);
        }
      } else {
        finalize(response);
      }
      stopLoader();
    }
    function convertStartAndEndPoints(response, optimisedRouteArr) {
      let optimisedRoute = null;
      for (let key in optimisedRouteArr) {
        optimisedRoute = optimisedRouteArr[key]
        if (optimisedRoute !== null) {
          //Build route
          var route = [];
          //Build the route win start and stop to create the line
          for (let i = 0; i < optimisedRoute.RouteNodes.length - 1; i++) {
            route[i] = {
              "start": { "X": optimisedRoute.RouteNodes[i].X, "Y": optimisedRoute.RouteNodes[i].Y },
              "stop": { "X": optimisedRoute.RouteNodes[i + 1].X, "Y": optimisedRoute.RouteNodes[i + 1].Y }
            };
          }
          optimisedRoute.RouteNodes = route;
        } else if (response.error !== null) {
          //Get errors from server
          throw response.error;
        }
      }
    }

    function getOptimisationResult(responseStart, userName) {
      // Before requesting server, block the method. After getting response, then setting idle by setResponseStatusIdle(). 
      setResponseStatus(RESPONSE_STATUS_BLOCKED);
      let response;
      let exceptionType;
      API.get(SMART_ROUTING_EV, process.env.REACT_APP_OPTIMISATION_RESULT + "?optimisation-id=" + responseStart.OptimisationId + "&user-name=" + userName)
        .then((responseJson) => {
          response = responseJson;
          setResponseStatus(RESPONSE_STATUS_IDLE);
          if (response != null) {
            if (response.Success) {
              setIsResponsed(true);
              setIsGetResultLoopOn(false);
              let iceErrorMsg = null;
              let optimisedRouteICE = response.ICERouting;
              if (optimisedRouteICE != null) {
                if (optimisedRouteICE.ErrorMessage != null) {
                  if (optimisedRouteICE.ExceptionType === 'System') {
                    exceptionType = 'System';
                    iceErrorMsg = unifiedErrorMsg;
                  } else {
                    iceErrorMsg = optimisedRouteICE.ErrorMessage;
                  }
                }
                setIceErrorMsg(iceErrorMsg);
                convertStartAndEndPoints(response, optimisedRouteICE.Routes);
              }
              let evErrorMsg = null;
              let optimisedRouteEV = response.EVRouting;
              if (optimisedRouteEV != null) {
                if (optimisedRouteEV.ErrorMessage != null) {
                  if (optimisedRouteEV.ExceptionType === 'System') {
                    exceptionType = 'System';
                    evErrorMsg = unifiedErrorMsg;
                  } else {
                    evErrorMsg = optimisedRouteEV.ErrorMessage;
                  }
                }
                setEvErrorMsg(evErrorMsg)
                convertStartAndEndPoints(response, optimisedRouteEV.Routes);
              }
              showDoubleErrorMessage(iceErrorMsg, evErrorMsg);
              if (exceptionType === 'System') {
                finalize(response);
              } else {
                //  Get result successfully and show the output panel with stop loading
                generateComparison(response);
              }

            } else {
              if (response.ProcessStatus > 0) {
                let addressesCount = geocodedAddresses.list.length + 5; // the default vehicle number is 5
                if (geocodedAddresses.list.length > 38) {
                  addressesCount += 60;
                }
                setLocatiumTimeout(addressesCount);
                setProcessStatus(response.ProcessStatus);
              }
              if (response.ErrorMessage != null && response.ErrorMessage.length > 0) {
                showErrorMessage(response.ErrorMessage);
                finalize(response);
              }
            }
          }
        })
        .catch((error) => {
          setResponseStatus(RESPONSE_STATUS_IDLE);
          if (error.response != null) {
            console.log("error.response.status" + error.response.status);
          }
          showErrorMessage(error);
        });
    }

    let timerPoolingResult;
    if (isGetResultLoopOn) {
      timerPoolingResult = setInterval(
        () => {
          if (responseStatus === RESPONSE_STATUS_IDLE) {
            getOptimisationResult(responseStart, cognitoUser);
          }
        }
        , 5000)
    } else {
      clearInterval(timerPoolingResult);

    }
    return () => clearInterval(timerPoolingResult);
  }, [cognitoUser, geocodedAddresses.list.length, isGetResultLoopOn, responseStart, responseStatus]);

  function showErrorMessage(errorMessage) {
    if (errorMessage != null && errorMessage.length > 0) {
      setIsGetResultLoopOn(false);
      console.error(errorMessage);
      alert("Warning:\r\n" + errorMessage);
    }
  }

  function showDoubleErrorMessage(iceErrorMsg, evErrorMsg) {
    let errorMessage = "";
    if (iceErrorMsg === evErrorMsg) {
      errorMessage = iceErrorMsg;
    } else {
      if (iceErrorMsg != null && iceErrorMsg.length > 0) {
        errorMessage = "Details ICE: " + iceErrorMsg;
      }
      if (evErrorMsg != null && evErrorMsg.length > 0) {
        errorMessage = "Details EV: " + evErrorMsg;
      }
    }
    if (errorMessage != null) {
      setIsGetResultLoopOn(false);
      console.error(errorMessage);
      alert("Warning:\r\n" + errorMessage);
    }
  }

  let geocodedListMarker;
  let multiRoutes;
  let addressesNotAssigned;
  let mapBounds;
  let polyline;
  if (currentTab === INTERNAL_COMBUSTION) {
    multiRoutes = iceResults.Routes;
    addressesNotAssigned = iceResults.NotAssigned;
  } else {
    multiRoutes = evResults.Routes;
    addressesNotAssigned = evResults.NotAssigned;
  }
  let addressesNotAssignedMarker;
  if (geocodedAddresses.list !== undefined) {
    if (multiRoutes.length === 0 && addressesNotAssigned.length === 0) {
      let addressList = [];
      addressList = addressList.concat(geocodedAddresses.list);
      if (geocodedAddresses.start?.Address !== undefined) {
        addressList.push(geocodedAddresses.start);
      }
      if (geocodedAddresses.end?.Address !== undefined) {
        addressList.push(geocodedAddresses.end);
      }
      mapBounds = updateBounds(addressList);
      //Display geocoded points from IQ Office
      geocodedListMarker = geocodedAddresses.list.map((address, idx) =>
        <MapMarker key={"markerG-" + idx} position={address} icon={geocodeIcon}></MapMarker>);
      //Start Marker
      if (geocodedAddresses.start?.Address !== undefined) {
        geocodedListMarker.push(<MapMarker key={"markerS-" + geocodedAddresses.start?.Address} position={geocodedAddresses.start} icon={startEndMarkerIcon} text={'S'}></MapMarker>)
      }
      //End Marker
      if ((geocodedAddresses.end?.Address !== undefined) &&
        (geocodedAddresses.end?.X !== geocodedAddresses.start?.X || geocodedAddresses.end?.Y !== geocodedAddresses.start?.Y)) {
        //Only if different than the start address
        geocodedListMarker.push(<MapMarker key={"markerE-" + geocodedAddresses.end?.Address} position={geocodedAddresses.end} icon={startEndMarkerIcon} text={'E'}></MapMarker>)
      }
    } else {
      geocodedListMarker = multiRoutes.map((route, id) => {
        return route.Addresses.map((address, idx) => {
          if (id === currentRountId || -1 === currentRountId) {
            if (route.Addresses.length === idx + 1) {
              if ((geocodedAddresses.end?.Address !== undefined)
                && (geocodedAddresses.start?.Address !== undefined)
                && (geocodedAddresses.start?.X === geocodedAddresses.end?.X)
                && (geocodedAddresses.start?.Y === geocodedAddresses.end?.Y)) {
                //Round trip Start = End so don't display the end marker
                return null;
              } else {
                //End Sequence
                return (<MapMarker key={'markerE' + id + '-' + idx} position={address} icon={startEndMarkerIcon} text={'E'}></MapMarker>);
              }
            } else if (idx === 0) {
              //Start or Stop Sequence
              return (<MapMarker key={'markerS' + id + '-' + idx} position={address} icon={startEndMarkerIcon} text={'S'}></MapMarker>);
            } else {
              return (<MapMarker key={'markerP' + id + '-' + idx} position={address} icon={customIconList[id]} color={COLOR_LIST[id]} text={idx}></MapMarker>);
            }
          } else {
            return null;
          }
        })
      })
      let addressBoundList = [];
      // Unassigned points should not be visible on map when individual Vehicle or route is selected
      addressesNotAssignedMarker = (-1 === currentRountId) ?
        addressesNotAssigned.map((notAssigned, id) => {
          return <MapMarker key={"markerNoAssigned-" + id} position={notAssigned} icon={unassignedIcon}></MapMarker>
        }) : null
      polyline = multiRoutes.map((route, id) => {
        if (id === currentRountId || -1 === currentRountId) {
          addressBoundList.push(...route.Addresses);
        }
        return route.RouteNodes.map((point, idx) => {
          if (id === currentRountId || -1 === currentRountId) {
            return < Polyline key={"Polyline" + id + "-" + idx} positions={[[point.start.Y, point.start.X], [point.stop.Y, point.stop.X]]} color={COLOR_LIST[id]} weight={5} opacity={0.8} />
          }
          return null;
        })
      })
      //When only unassigned points, setting them to update bounds
      if (-1 === currentRountId && addressesNotAssigned.length > 0) {
        addressBoundList.push(...addressesNotAssigned);
      }
      mapBounds = updateBounds(addressBoundList);
    }
  }

  //Geojson element needs it's key changed to force re-rendering of data
  return (
    <div className={`${styles.mapWrapper} row gx-1`} >
      <Tutorial handlePanelSelect={setPanelActiveKey} initRun={isRun} />
      <LoadingSpinner visible={showLoader} stopLoader={stopTotal} locatiumTimeout={locatiumTimeout} processStatus={processStatus} />
      <Accordion id={styles.accordionLocationDirection}
        activeKey={panelActiveKey} onSelect={setPanelActiveKey} className="col-md-4 printhide">
        <Accordion.Item eventKey="1">
          <PanelHeader eventKey="1" panelActiveKey={panelActiveKey} title={'Address List'}>{<i className={`bi bi-signpost-split`} />}</PanelHeader>
          <Accordion.Body style={{ height: panelHeight }} className={styles.panelBody}>
            <LocationEntry geocodesCallback={setGeocodesCallback} startLoaderCallback={startLoader}
              stopLoaderCallback={stopLoader} addressErrorsCallback={setAddressErrors} />
            {(addressListError?.length !== undefined && addressListError?.length > 0) ?
              <AddressListError addressListError={addressListError}></AddressListError> : null}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item id="inputsPanel" eventKey="2">
          <PanelHeader eventKey="2" panelActiveKey={panelActiveKey} title={'Inputs'}>{<i className={`bi bi-sliders2`} />}</PanelHeader>
          <Accordion.Body style={{ height: panelHeight }} className={styles.panelBody}>
            {geocodedAddresses.start.X !== undefined && geocodedAddresses.end.X !== undefined ?
              <InputPanel startPoint={geocodedAddresses.start} endPoint={geocodedAddresses.end} points={geocodedAddresses.list}
                cognitoUser={cognitoUser} startOptimisation={setInputsData} />
              : <p>You must add addresses, then click Display Addresses on Map, in order to see the Inputs panels.</p>}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item id="resultsPanel" eventKey="3">
          <PanelHeader eventKey="3" panelActiveKey={panelActiveKey} title={'Results'}>{<GraphsSvg />}</PanelHeader>
          <Accordion.Body style={{ height: panelHeight }} className={styles.panelBody}>
            {geocodedAddresses.list != null && isResponsed === true ?
              <div ref={componentInputAndComparisonToPrint} >
                <PrintHeader />
                <InputReport generalInputs={inputsData.body.GeneralInputs} iceInputs={inputsData.body.ICEInputs} electricInputs={inputsData.body.ElectricInputs} />
                <OutputPanel iceStatistic={iceResults.Statistic} evStatistic={evResults.Statistic} multiRoutes={multiRoutes} electricInputs={inputsData.body.ElectricInputs}
                  selectRoutes={selectRoutes} iceErrorMsg={iceErrorMsg} evErrorMsg={evErrorMsg} />
                <PrintButtonArea mapHeight={mapHeight} componentInputAndComparisonToPrint={componentInputAndComparisonToPrint} componentMapToPrint={componentMapToPrint} />
              </div> : <p>You must add addresses, click Display Addresses on Map, complete all input tabs, and click Generate Comparison, in order to see results.</p>}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div id="printDivMap" className="col-md-8" ref={componentMapToPrint}>
        <PrintHeader />
        <Ribbon />
        <MapWrapper mapBounds={mapBounds} multiRoutes={multiRoutes} addressesNotAssigned={addressesNotAssigned}
          currentTab={currentTab} geocodedListMarker={geocodedListMarker} addressesNotAssignedMarker={addressesNotAssignedMarker} polyline={polyline} />
      </div>
    </div >
  )
}