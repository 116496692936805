import React from "react";
import { Watch } from 'react-loader-spinner'
import { Button } from "react-bootstrap";
import CountDown from './CountDown';
import styles from './LoadingSpinner.module.css';

export default function LoadingSpinner(props) {
   const { visible, stopLoader, locatiumTimeout, processStatus } = props;
   function handleCancelOnClick() {
      stopLoader();
   }

   return (

      <div>{visible ?
         <div className={styles.layer}>
            <div className={styles.spinnerBox}>
               <Watch
                  color="#00B7ED"
                  height={60}
                  width={60}
                  visible={visible}
                  ariaLabel="watch-loading"
               />
               {locatiumTimeout !== 0 ?
                  <Button className={`${styles.button} mt-2`} variant="primary" onClick={handleCancelOnClick}>Cancel</Button> :
                  <Button className={`${styles.button} mt-2`} variant="primary" disabled>Cancel</Button>}
               <CountDown inputSec={locatiumTimeout} processStatus={processStatus} />
            </div>
         </div> : null}
      </div>
   );
}