import React, { useState, useEffect, useCallback } from "react";
import { InputGroup, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import styles from './TextInputField.module.css';

export default function TextInputField(props) {

    const inputField = props.inputField;
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    const removeComma = useCallback((fieldValue) => {
        return (fieldValue !== "" && fieldValue !== ".") ?
            parseFloat(fieldValue.replace(/,/g, "")).toFixed(inputField.DECIMAL) :
            fieldValue;
    }, [inputField.DECIMAL]);

    useEffect(() => {
        let input = removeComma(props.inputValue);
        if (input < inputField.MIN || input > inputField.MAX) {
            props.setFieldError('error');
        } else {
            props.setFieldError(null);
        }
    }, [props.inputValue, inputField, removeComma, props]);

    function makeInputFieldMessage() {
        return `Enter a value between ${inputField.MIN} and ${inputField.MAX.toLocaleString()}`;
    }

    /**
     * 
     * @param {*} fieldValue 
     * @param {*} fieldName 
     * @param {*} min 
     * @param {*} max 
     */
    function handleInputFieldOnChange(event) {
        let decimal = inputField.DECIMAL;
        let fieldValue = event.target.value;
        let inputCondition;
        if (decimal === 0) {
            //inputCondition = /^(-)?[0-9]*$/.test(fieldValue);
            inputCondition = /^[0-9]*$/.test(fieldValue);
        } else if (decimal > 0) {
            inputCondition = !isNaN(fieldValue) || fieldValue === '.' || fieldValue === '-';
        } else {
            console.error("Decimal is allowed only 0, 1, 2 and 3.");
        }
        if (inputCondition) {
            if (fieldValue === '' || fieldValue === '.' || fieldValue === '-' || fieldValue < inputField.MIN || fieldValue > inputField.MAX) {
                props.setFieldError('error');
                setShowErrorMsg(true);
            } else {
                props.setFieldError(null);
                setShowErrorMsg(false);
            }
            props.setFieldValue(fieldValue);
        }
    }
    function handleInputFieldOnFocus(event) {
        props.setFieldValue(removeComma(event.target.value));
        if (props.fieldError === 'error') {
            setShowErrorMsg(true);
        }
    }
    function handleInputFieldOnBlur(event) {
        props.setFieldValue(addComma(event.target.value));
    }
    function addComma(fieldValue) {
        return (fieldValue !== "" && fieldValue !== ".") ?
            parseFloat(fieldValue).toLocaleString(undefined, {
                minimumFractionDigits: inputField.DECIMAL,
                maximumFractionDigits: inputField.DECIMAL
            }) :
            fieldValue;
    }
    return (
        <InputGroup className={`${styles.inputGroupCustom} mb-3`} hasValidation>
            {inputField.POPUP != null ?
                <InputGroup.Text id={styles.inputGroupAddonCustom}>
                    {inputField.LABEL}
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">{inputField.POPUP}</Tooltip>} delayHide={150}>
                        <sup><i className={`ms-1 bi bi-question-circle-fill`} /></sup>
                    </OverlayTrigger>
                </InputGroup.Text>
                : <InputGroup.Text id={styles.inputGroupAddonCustom}>{inputField.LABEL}</InputGroup.Text>
            }
            <Form.Control type="text" value={props.inputValue} placeholder={makeInputFieldMessage()} onChange={(e) => handleInputFieldOnChange(e)}
                onFocus={(e) => handleInputFieldOnFocus(e)} onBlur={(e) => handleInputFieldOnBlur(e)} isInvalid={showErrorMsg} />
            {props.unit}
            <Form.Control.Feedback type="invalid" className={styles.textEnd}>
                {makeInputFieldMessage()}
            </Form.Control.Feedback>
        </InputGroup>
    )
}