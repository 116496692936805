import React, { useState, useCallback } from "react";
import { Button, Form, OverlayTrigger, Tooltip, Card, DropdownButton, Dropdown } from "react-bootstrap";
import UnmatchAddress from "./UnmatchAddress";
import DeliveryAddressGrid from "./DeliveryAddressGrid";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import styles from "./LocationEntry.module.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { SYSTEM_STATUS_SWITCH, SMART_ROUTING_EV, IQOFFICE_VALIDATE, NUMBER_ADDRESS_LIMIT } from "../AppConfig";
import { API } from "aws-amplify";

const emptyAddressRows = new Array(NUMBER_ADDRESS_LIMIT).fill().map((_, i) => { return { id: i, address: "" } });

// the points to differenciate between SouthIsland and NorthIsland
const cookStraitPointX = 174.5;
const cookStraitPointY = -40.3;
export default function LocationEntry(props) {
    const SAME_ADDRESS_MESSAGE = "At least 2 addresses are required in order to be able to generate a route. Please enter at least one more address, and define which is the start address if known."
    const [startAddressInput, setStartAddressInput] = useState('');
    const [endAddressInput, setEndAddressInput] = useState('');
    const [startSelected, setStartSelected] = useState([]);
    const [endSelected, setEndSelected] = useState([]);
    const [unmatchedAddress, setUnmatchedAddress] = useState({ startAddress: [], endAddress: [], addressList: [] });
    const [geocodedStartAddress, setGeocodedStartAddress] = useState({});
    const [geocodedEndAddress, setGeocodedEndAddress] = useState({});
    const [geocodedListClean, setGeocodedListClean] = useState([]);
    const [deliveryAddressGridList, setDeliveryAddressGridList] = useState(emptyAddressRows);
    const [sampleKey, setSampleKey] = useState(-1);
    const [sampleValue, setSampleValue] = useState("Choose city");
    const [startTypeAheadListIsLoading, setStartTypeAheadListIsLoading] = useState(false);
    const [startTypeAheadListOptions, setStartTypeAheadListOptions] = useState([]);
    const [endTypeAheadListIsLoading, setEndTypeAheadListIsLoading] = useState(false);
    const [endTypeAheadListOptions, setEndTypeAheadListOptions] = useState([]);

    function processGeocodeAddress(response, address) {
        if (response === undefined) {
            return address;
        }

        //Result from rest validate or list API
        if (!Array.isArray(response)) {
            return response;
        } else if (response.length === 0) {
            //Error no result found.
            return address;
        } else if (response.length === 1) {
            //Only one result then that the one.
            return response[0];
        } else if (response[0]?.address === address) {
            //First result match the input then that the one.
            return response[0];
        } else if (response[1]?.address === address) {
            //Second result match the input then that the one.
            return response[0];
        } else if (response[200]?.address === address) {
            //Third result match the input then that the one.
            return response[0];
        } else {
            //Return the array
            return response;
        }
    }

    function clearAddressList(e) {
        e.preventDefault();
        //Reset Grid
        let rowsTemp = deliveryAddressGridList;
        for (let index = 0; index < rowsTemp.length; index++) {
            rowsTemp[index] = { id: index, address: "" };
        }

        setStartAddressInput('');
        setEndAddressInput('');
        setStartSelected([]);
        setEndSelected([]);

        setUnmatchedAddress({ startAddress: [], endAddress: [], addressList: [] });
        setGeocodedStartAddress([]);
        setGeocodedEndAddress([]);
        setGeocodedListClean([]);
        setDeliveryAddressGridList(rowsTemp);
        setStartTypeAheadListIsLoading(false);
        setStartTypeAheadListOptions([]);
        setEndTypeAheadListIsLoading(false);
        setEndTypeAheadListOptions([]);

        props.addressErrorsCallback([]);
        props.geocodesCallback({}, {}, [], []);
    }

    function handleSampleOnSelect(evtKey, evt) {
        setSampleKey(evtKey);
        setSampleValue(evt.target.textContent);
    };

    function handleSampleButtonClick(e) {
        e.preventDefault();
        //Reset Grid
        let rowsTemp = emptyAddressRows;
        let samples = require('../assets/data/samples.json');
        let data;
        if (samples != null) {
            data = samples[sampleKey];
            if (data != null) {
                for (let index = 0; index < 14; index++) {
                    rowsTemp[index] = { id: index, address: data[index + 1] };
                }
                setDeliveryAddressGridList(rowsTemp);
                setStartSelected([data[0]]);
                setEndSelected([data[15]]);
            }
        }
    }

    async function handleSubmitAddresses(event) {
        event.preventDefault();
        //Reset list
        setUnmatchedAddress({ startAddress: [], endAddress: [], addressList: [] });

        setGeocodedStartAddress([]);
        setGeocodedEndAddress([]);
        setGeocodedListClean([]);
        // setAddressListError([]);
        setStartTypeAheadListOptions([]);
        setEndTypeAheadListOptions([]);
        //Set the empty in bound first in order the map can jump  to right position
        props.geocodesCallback({}, {}, [], []);
        //Read the information from the UI
        let tempStartAddress = startSelected.length > 0 ? startSelected[0] : startAddressInput;
        //remove blanks
        let tempAddressList = deliveryAddressGridList.map((item) => { return { "Address": item["address"] } })
            .filter(address => address.Address !== "");
        let tempEndAddress = endSelected.length > 0 ? endSelected[0] : endAddressInput;

        if (tempStartAddress.length === 0 || tempEndAddress.length === 0) {
            alert("Both Start and End addresses are required in order to be able to generate a route.\nPlease enter a Start Address and an End Address.");
            return;
        }

        //Limit addresses
        if (tempAddressList.length > NUMBER_ADDRESS_LIMIT) {
            alert("Only the first " + NUMBER_ADDRESS_LIMIT + " addresses will get optimised.\nPlease contact info@critchlow.co.nz if you want to optimise more addresses.");
            tempAddressList = tempAddressList.slice(0, NUMBER_ADDRESS_LIMIT);
        }
        if (tempAddressList.length === 0) {
            if (tempStartAddress === tempEndAddress) {
                alert(SAME_ADDRESS_MESSAGE);
                return;
            }
        }
        if (tempAddressList.filter(e => e.Address === tempStartAddress || e.Name === tempEndAddress).length > 0) {
            alert("The start address is also included in the destination list. Please remove it from the destination address list.");
            return;
        }
        props.startLoaderCallback();
        //addressList is an array of [{string,string,string}][{address,id,details}]
        let validatedStart = await AddressValidator(tempStartAddress);
        let validatedEnd = await AddressValidator(tempEndAddress);
        let startAddressGeocoded = processGeocodeAddress(validatedStart, tempStartAddress);
        let endAddressGeocoded = processGeocodeAddress(validatedEnd, tempEndAddress);
        let geocodedList = await Promise.all(tempAddressList.map(
            async (address) =>
                processGeocodeAddress(await AddressValidator(address.Address), address.Address)
        ));

        let unmatchedStartAddress;
        let unmatchedEndAddress;
        let unmatchedList = [];
        geocodedList.forEach(
            (address, index) => {
                if (Array.isArray(address)) {
                    unmatchedList.push({ address: address, order: index })
                }
            }
        );

        let geocodedListClean = geocodedList.filter(address => !Array.isArray(address) && (address?.X !== undefined));
        let addressListError = geocodedList.filter(address => !Array.isArray(address) && (address?.X === undefined));

        if (Array.isArray(startAddressGeocoded)) {
            unmatchedStartAddress = startAddressGeocoded;
        } else {
            unmatchedStartAddress = "";
            if (startAddressGeocoded?.X === undefined) {
                addressListError.push(startAddressGeocoded);
            }
        }

        if (Array.isArray(endAddressGeocoded)) {
            unmatchedEndAddress = endAddressGeocoded;
        } else {
            unmatchedEndAddress = "";
            if (endAddressGeocoded?.X === undefined) {
                addressListError.push(endAddressGeocoded);
            }
        }

        //Send the addresses not matched back up to SmartRouting for display
        props.addressErrorsCallback(addressListError);

        if (unmatchedStartAddress.length > 0 || unmatchedList.length > 0 || unmatchedEndAddress.length > 0 || addressListError.length > 0) {
            //Some addressed not matched
            props.stopLoaderCallback();
            setGeocodedStartAddress(startAddressGeocoded);
            setGeocodedEndAddress(endAddressGeocoded);
            setGeocodedListClean(geocodedListClean);

            setUnmatchedAddress({ startAddress: unmatchedStartAddress, endAddress: unmatchedEndAddress, addressList: unmatchedList });
            // setAddressListError(addressListError);
        } else {
            if (geocodedList.length === 0) {
                if (startAddressGeocoded.Y === endAddressGeocoded.Y && startAddressGeocoded.X === endAddressGeocoded.X) {
                    props.stopLoaderCallback();
                    alert(SAME_ADDRESS_MESSAGE);
                    return;
                }
            }
            let isNorthIsland = false;
            let isSouthIsland = false;
            //Start Point
            if ((startAddressGeocoded?.Y < cookStraitPointY) && (startAddressGeocoded?.X < cookStraitPointX)) {
                isSouthIsland = true;
            } else {
                isNorthIsland = true;
            }
            //End Point
            if ((endAddressGeocoded.Y < cookStraitPointY) && (endAddressGeocoded.X < cookStraitPointX)) {
                isSouthIsland = true;
            } else {
                isNorthIsland = true;
            }
            //All Points
            geocodedList.map(point => {
                if ((point.Y < cookStraitPointY) && (point.X < cookStraitPointX)) {
                    return isSouthIsland = true;
                } else {
                    return isNorthIsland = true;
                }

            });
            if (isNorthIsland && isSouthIsland) {
                //Error different island
                props.stopLoaderCallback();
                alert("The addresses need to be on the same island.");
                return;
            }
            //All the addresses match
            //Send results back to Parent container
            props.geocodesCallback(startAddressGeocoded, endAddressGeocoded, geocodedListClean, addressListError);
        }
    }
    async function AddressValidator(address) {
        if (address === undefined || address === '') {
            return;
        }
        //ValidateAddress IQ Office use API Gateway
        try {
            return await API.get(SMART_ROUTING_EV, IQOFFICE_VALIDATE + '?addressLine=' + address + '&geocodes=LatLong');
        } catch (error) {
            console.error("ValidateAddress error: " + error);
            return {
                inputAddress: address,
                error: "ValidateAddress error"
            };
        }
    }

    function submitMatchAddress(geocodedStartAddress, geocodedEndAddress, geocodedListMatch) {
        //Merge the 2 lists: geocodedListClean and geocodedListMatch
        let newGeocodedListClean = [...geocodedListClean, ...geocodedListMatch];
        setUnmatchedAddress({ startAddress: [], endAddress: [], addressList: [] });
        setGeocodedListClean(newGeocodedListClean);
        if (geocodedStartAddress.Address !== undefined) {
            setStartSelected([geocodedStartAddress.Address]);
        }
        if (geocodedEndAddress.Address !== undefined) {
            setEndSelected([geocodedEndAddress.Address]);
        }
        geocodedListMatch.forEach((item, i) => {
            deliveryAddressGridList[item.order] = { address: item.Address }
        })
        setDeliveryAddressGridList(deliveryAddressGridList);
    }

    function handleStartSelectChange(addressArr) {
        setStartSelected(addressArr);
    }
    function handleEndSelectChange(addressArr) {
        setEndSelected(addressArr);
    }
    function handleStartInputChange(input) {
        setStartAddressInput(input);
    }
    function handleEndInputChange(input) {
        setEndAddressInput(input);
    }
    const handleStartSearch =
        useCallback(async (query) => {
            const result = await handleSearch(query);
            setStartTypeAheadListIsLoading(result["typeAheadListIsLoading"]);
            setStartTypeAheadListOptions(result["typeAheadListOptions"]);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
    const handleEndSearch =
        useCallback(async (query) => {
            const result = await handleSearch(query);
            setEndTypeAheadListIsLoading(result["typeAheadListIsLoading"]);
            setEndTypeAheadListOptions(result["typeAheadListOptions"]);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
    async function handleSearch(query) {
        let response = await AddressValidator(query);
        let typeAheadListIsLoading = true;
        let typeAheadListOptions;
        //if error
        if (response === undefined) {
            typeAheadListIsLoading = false;
            typeAheadListOptions = [];
        }
        //Result from rest validate or list API 
        if (Array.isArray(response)) {
            //Convert array to string array
            typeAheadListIsLoading = false;
            typeAheadListOptions = response.map(addressResult => addressResult.Address);
        } else {
            typeAheadListIsLoading = false;
            //Create an array of one element
            typeAheadListOptions = response.Address ? [response.Address] : []
        }
        return {
            "typeAheadListIsLoading": typeAheadListIsLoading,
            "typeAheadListOptions": (typeAheadListOptions.length === 1 && typeAheadListOptions[0] === undefined) ? [] : typeAheadListOptions
        }
    }
    function handleStartFocus() {
        setStartTypeAheadListOptions([]);
    }
    function handleEndFocus() {
        setEndTypeAheadListOptions([]);
    }

    const tooltipTitleClearList = (<Tooltip id="tooltipTitleClearList">Click here to clear the Destination Addresses table.</Tooltip>);
    const tooltipAddresses = (<Tooltip id="tooltipAddresses">Paste your destination addresses here. If you only have a single destination which is the same as your end address, simply copy the end address into the destination panel.</Tooltip>);
    const tooltipTitleUnmatchedAddress = (<Tooltip id="tooltipTitleUnmatchedAddress">For each address below, select the correct address from the drop-down list.</Tooltip>);
    const tooltipPlaceAddresses = (<Tooltip id="tooltipPlaceAddresses">Click here to place the listed addresses on the map.<br />If you make changes to the Address List, click here again.</Tooltip>);

    return (
        <Form className={styles.root}>
            <Card id="sampleAddress" bg="light" className={`${styles.textCenter} mb-3`}>
                <Card.Body>
                    {SYSTEM_STATUS_SWITCH
                        ?
                        <>
                            <Form.Label>
                                <span>If you do not have sample addresses, please use the samples available here.</span>
                                <br />
                                <span>Sample Address Set Selection</span>
                            </Form.Label>
                            <div className={styles.inlineFlex}>
                                <span id={styles.spanDelivery}>
                                    <DropdownButton id={styles.sampleCity} title={sampleValue} key={sampleKey} variant="outline-secondary"
                                        onSelect={handleSampleOnSelect} >
                                        <Dropdown.Item eventKey="0">Auckland</Dropdown.Item>
                                        <Dropdown.Item eventKey="1">Wellington</Dropdown.Item>
                                        <Dropdown.Item eventKey="2">Christchurch</Dropdown.Item>
                                        <Dropdown.Item eventKey="3">Dunedin</Dropdown.Item>
                                        <Dropdown.Item eventKey="4">Auckland - Hamilton </Dropdown.Item>
                                        <Dropdown.Item eventKey="5">Nelson - Blenheim</Dropdown.Item>
                                        <Dropdown.Item eventKey="6">Christchurch - Timaru</Dropdown.Item>
                                    </DropdownButton>
                                </span>
                                <Button className={styles.rightSide} variant="primary" id="sampleButton" onClick={handleSampleButtonClick}>Fill Sample</Button>
                            </div>
                        </>
                        :
                        <Form.Label>
                            <span className={styles.errorMsg}>The route optimisation service for SwitchMyFleet is currently unavailable.</span>
                            <br />
                            <span className={styles.errorMsg}>Please try again later. We apologise for any inconvenience.</span>
                        </Form.Label>}
                </Card.Body>
            </Card>
            <Form.Group className="mb-3" id="startAddressText" controlId="locationEntryForm.StartAddressText">
                <Form.Label>Start Address</Form.Label>
                <AsyncTypeahead
                    id="startAddressSearch"
                    labelKey="startAddress"
                    isLoading={startTypeAheadListIsLoading}
                    options={startTypeAheadListOptions}
                    onSearch={handleStartSearch}
                    filterBy={() => { return true }}
                    placeholder="e.g. 1 main street, blenheim"
                    maxResults={6}
                    useCache={false}
                    onChange={handleStartSelectChange}
                    onInputChange={handleStartInputChange}
                    onFocus={handleStartFocus}
                    selected={startSelected}
                />
            </Form.Group>

            <Form.Group className="mb-3" id="addressInputTextArea" controlId="locationEntryForm.AddressInputTextArea">
                <div className={styles.inlineFlex}>
                    <Form.Label>Destination Addresses</Form.Label>
                    <OverlayTrigger placement="bottom" overlay={tooltipAddresses} delayHide={150}>
                        <sup><i className={"ms-1 bi bi-question-circle-fill"} /></sup>
                    </OverlayTrigger>
                    <Button variant="danger" id="bpClearList" className={`mb-1 ${styles.rightSide}`}
                        onClick={e => window.confirm("WARNING: Do you wish to delete this data?  There is no undo.") && clearAddressList(e)}>
                        <i className={"bi bi-trash3"}></i>Clear List
                        <OverlayTrigger placement="bottom" overlay={tooltipTitleClearList} delayHide={150}>
                            <sup><i className={"ms-1 bi bi-question-circle-fill"} /></sup>
                        </OverlayTrigger>
                    </Button>
                </div>
                <DeliveryAddressGrid deliveryAddressGridList={deliveryAddressGridList}
                    refreshRowsCallBack={setDeliveryAddressGridList} numberOfAddressLimitation={NUMBER_ADDRESS_LIMIT}>
                </DeliveryAddressGrid>
            </Form.Group>

            <Form.Group className="mb-3" id="endAddressText" controlId="locationEntryForm.EndAddressText">
                <Form.Label>End Address</Form.Label>
                <AsyncTypeahead
                    id="endAddressSearch"
                    labelKey="endAddress"
                    isLoading={endTypeAheadListIsLoading}
                    options={endTypeAheadListOptions}
                    onSearch={handleEndSearch}
                    filterBy={() => { return true }}
                    placeholder="e.g. 1 main street, blenheim"
                    maxResults={6}
                    useCache={false}
                    onChange={handleEndSelectChange}
                    onInputChange={handleEndInputChange}
                    onFocus={handleEndFocus}
                    selected={endSelected}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="locationEntryForm.SubmitAddress" id={styles.locationEntryFormSubmitAddress}>
                <Button variant="primary" id="submitLocations" onClick={handleSubmitAddresses}
                    disabled={!SYSTEM_STATUS_SWITCH} >
                    {SYSTEM_STATUS_SWITCH ? <span></span>
                        : <i className={"bi bi-slash-circle"} />}
                    Display Addresses On Map
                    <OverlayTrigger placement="top" overlay={tooltipPlaceAddresses} delayHide={150}>
                        <sup><i className={"ms-1 bi bi-question-circle-fill"} /></sup>
                    </OverlayTrigger></Button>
            </Form.Group>
            {(unmatchedAddress.addressList?.length !== undefined && unmatchedAddress.addressList?.length > 0) ||
                (unmatchedAddress.startAddress?.length !== undefined && unmatchedAddress.startAddress?.length > 0) ||
                (unmatchedAddress.endAddress?.length !== undefined && unmatchedAddress.endAddress?.length > 0) ?
                <Form.Group className="mb-3" controlId="locationEntryForm.UnmatchedAddress">
                    <Form.Label className={styles.formGroupTitle}>Confirm the Correct Addresses</Form.Label>
                    <OverlayTrigger placement="bottom" overlay={tooltipTitleUnmatchedAddress} delayHide={150}>
                        <sup><i className={"ms-1 bi bi-question-circle-fill"} /></sup>
                    </OverlayTrigger>
                    <UnmatchAddress geocodedStartAddress={geocodedStartAddress}
                        geocodedEndAddress={geocodedEndAddress}
                        unmatchedStartAddress={unmatchedAddress.startAddress}
                        unmatchedEndAddress={unmatchedAddress.endAddress}
                        unmatchedList={unmatchedAddress.addressList}
                        submitMatchAddressCallBack={submitMatchAddress} />
                </Form.Group>
                : null
            }
        </Form >

    )
}
