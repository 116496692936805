import React from "react";
import { useAppContext } from "../libs/contextLib";
import styles from "./Tutorial.module.css";
import Joyride, { ACTIONS, STATUS, EVENTS } from 'react-joyride';
import { NUMBER_ADDRESS_LIMIT } from "../AppConfig";

export default function Tutorial({ handlePanelSelect, initRun }) {
    const { setRunAccessTutorial } = useAppContext();
    const [run, setRun] = React.useState(initRun);
    const [stepIndex, setStepIndex] = React.useState(0);
    React.useEffect(() => {
        setRun(initRun)
    }, [initRun]);

    const steps = [
        {
            title: <p className={styles.coachMarkTitle}>Welcome to SwitchMyFleet</p>,
            content: <div> SwitchMyFleet provides operational cost comparisons for New Zealand fleet operators that are considering switching to electric vehicles.<br /><br />We welcome any feedback.</div>,
            disableBeacon: true,
            locale: { skip: 'Section 0 of 3', next: 'Let’s get started →' },
            placement: 'center',
            target: 'body',
            styles: {
                options: {
                    width: 400,
                },
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>Choose a Sample of Addresses</p>,
            content: 'You can choose to use a set of sample addresses from the “Choose city” drop-down list. Alternatively, you can ignore this and use your own address data.',
            disableBeacon: true,
            target: '#sampleAddress',
            locale: { skip: 'Section 1 of 3', next: 'Next →' },
            placement: 'right',
            styles: {
                options: {
                    width: 350,
                },
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>Start Address</p>,
            content: 'Your start address goes here. Start typing an address to find a match.',
            disableBeacon: true,
            target: '#startAddressText',
            locale: { skip: 'Section 1 of 3', next: 'Next →' },
            placement: 'right',
            styles: {
                options: {
                    width: 350,
                },
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>Destination Addresses</p>,
            content: `Your destination addresses go here. One address per line up to ${NUMBER_ADDRESS_LIMIT}. You can copy-paste from a table/cell-based application (e.g. Excel).`,
            disableBeacon: true,
            locale: { skip: 'Section 1 of 3', next: 'Next →' },
            placement: 'right',
            target: '#addressInputTextArea',
            styles: {
                options: {
                    width: 350,
                }
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>End Address</p>,
            content: 'Your end address goes here. Start typing an address to find a match. This can be the same or different to your start address.',
            disableBeacon: true,
            locale: { skip: 'Section 1 of 3', next: 'Next →' },
            placement: 'right',
            target: '#endAddressText',
            styles: {
                options: {
                    width: 350,
                }
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>Display Addresses On Map</p>,
            content:
                <div>
                    Once you have your addresses filled in, click here to display these on the map.
                    <p className={styles.fontItalic}><span className={styles.pickFont}>Note: </span>
                        If an address is incorrect, an error prompt will help you resolve it.</p>
                </div>
            ,
            disableBeacon: true,
            locale: { skip: 'Section 1 of 3', next: 'Next →' },
            placement: 'right',
            target: '#submitLocations',
            styles: {
                options: {
                    width: 350,
                }
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>Inputs</p>,
            content: 'The Inputs pane is where you will select parameter inputs (there are default values for each). More detail on the inputs can be found in the User Guide.',
            disableBeacon: true,
            locale: { skip: 'Section 2 of 3', next: 'Next →' },
            placement: 'right-end',
            target: '#inputsPanel',
            styles: {
                options: {
                    width: 350,
                }
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>Results</p>,
            content:
                <div>
                    Once your inputs have been processed, the results will be displayed in this pane, and the routes will be displayed on the map.
                    <p className={styles.fontItalic}><span className={styles.pickFont}>Note: </span>
                        It may take a minute or so to generate the results.</p>
                </div>,
            disableBeacon: true,
            locale: { skip: 'Section 2 of 3', next: 'Next →' },
            placement: 'right-end',
            target: '#resultsPanel',
            styles: {
                options: {
                    width: 350,
                }
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>Access Tutorial</p>,
            content: <div>Access this tutorial at any time.</div>,
            disableBeacon: true,
            locale: { skip: 'Section 3 of 3', next: 'Next →' },
            placement: 'bottom',
            target: '#accessTutorial',
            styles: {
                options: {
                    width: 350,
                }
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>User Guide</p>,
            content: <div>Click here to access the User Guide.</div>,
            disableBeacon: true,
            locale: { skip: 'Section 3 of 3', next: 'Next →' },
            placement: 'bottom',
            target: '#userGuide',
            styles: {
                options: {
                    width: 350,
                }
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>Feedback or Support</p>,
            content: <div>This will take you to the Critchlow Geospatial website where you can fill out a quick form to provide feedback or to request support.</div>,
            disableBeacon: true,
            locale: { skip: 'Section 3 of 3', next: 'Next →' },
            placement: 'bottom',
            target: '#feedback',
            styles: {
                options: {
                    width: 350,
                }
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>Information</p>,
            content: <div>Here you can find the <span className={styles.fontItalic}>Terms of Service</span> for SwitchMyFleet. We recommend you read these terms.</div>,
            disableBeacon: true,
            locale: { skip: 'Section 3 of 3', next: 'Next →' },
            placement: 'bottom-end',
            target: '#information',
            styles: {
                options: {
                    width: 350,
                }
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>Logout/Login</p>,
            content: <div>Here you can logout/login to SwitchMyFleet.</div>,
            disableBeacon: true,
            locale: { skip: 'Section 3 of 3', next: 'Next →' },
            placement: 'bottom-end',
            target: '#logout',
            styles: {
                options: {
                    width: 350,
                }
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>EVRoam Chargers</p>,
            content:
                <div>
                    Here you can toggle on/off Electric Vehicle Charging Stations across the country.
                    These are sourced from EVRoam (maintained by Waka Kotahi NZ Transport Agency).
                </div>
            ,
            disableBeacon: true,
            locale: { skip: 'Section 3 of 3', next: 'Next →' },
            placement: 'bottom-end',
            target: '#switch-chargers-label',
            styles: {
                options: {
                    width: 350,
                }
            }
        },
        {
            title: <p className={styles.coachMarkTitle}>Thank you</p>,
            content: <div>To access this tutorial again, click the 'Access Tutorial' icon found on the top-right navigation bar.</div>,
            disableBeacon: true,
            locale: { skip: '', last: 'End Tutorial' },
            placement: 'center',
            hideBackButton: true,
            target: '#accessTutorial',
            styles: {
                options: {
                    width: 400,
                }
            }
        },
    ];

    function handleJoyrideCallback(data) {
        const { action, index, status, type } = data;
        handlePanelSelect('1'); // open the first panel in order to avoid a fixed scroll issue
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour
            if (action === ACTIONS.CLOSE) {
                setStepIndex(14);
            } else {
                setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
            }

        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
            setStepIndex(0);
            setRun(false);
            setRunAccessTutorial(false);
        }
    }

    return (
        <Joyride
            callback={handleJoyrideCallback}
            continuous={true}
            run={run}
            stepIndex={stepIndex}
            showProgress={false}
            showSkipButton={true}
            disableOverlayClose={true}
            steps={steps}
            disableScrollParentFix={true}
            styles={{
                options: {
                    primaryColor: '#ffc322',
                    zIndex: 10000,
                },
            }}
        />
    );
}

