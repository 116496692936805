import React from "react";
import { Tabs, Tab, Table, Tooltip, OverlayTrigger, Button, Form } from "react-bootstrap";
import { ReactComponent as ComparisonSvg } from "../assets/icons/tabs/comparison.svg";
import { ReactComponent as IceSvg } from "../assets/icons/tabs/ice.svg";
import { ReactComponent as ElecSvg } from "../assets/icons/tabs/elec.svg";
import { ReactComponent as ElecBigSvg } from "../assets/icons/outputs/elec_big.svg";
import { ReactComponent as IceBigSvg } from "../assets/icons/outputs/ice_big.svg";
import { COLOR_LIST, ELECTRIC, INTERNAL_COMBUSTION, VEHICLE_TYPE } from "../AppConfig";

import styles from "./OutputPanel.module.css";

export default function OutputPanel(props) {
    function handleEvOrIceOnClick(key) {
        handleTabRoutesOnClick(key, -1);
    }
    function handleTabRoutesOnClick(tab, routeId) {
        props.selectRoutes(tab, routeId);
    }
    function formatTime(mins) {
        let minsNumber = Number(mins);
        if (isNaN(minsNumber)) {
            return '';
        } else {
            minsNumber = minsNumber.toFixed(); //note rounding, no fractional part
        }
        let hourPart = Math.floor(minsNumber / 60);
        let minPart = Math.round(minsNumber % 60);
        return hourPart.toString().padStart(2, '0') + ':' + minPart.toString().padStart(2, '0');
    }
    function formatDistance(inputNumber) {
        if (inputNumber >= 100) {
            return formatNumberDecimals(inputNumber, 0);
        } else {
            return formatNumberDecimals(inputNumber, 1);
        }
    }
    function formatNumberDecimals(inputNumber, decimalLength) {
        let convertedNumber = Number(inputNumber).toFixed(decimalLength);
        if (isNaN(convertedNumber)) {
            return '';
        }
        return convertedNumber;
    }
    /**
     * formate or convert from key number To vehiche type name
     * @param {*} typeKey 
     */
    function convertToVehicleType(typeKey) {
        for (const vehicleItem of VEHICLE_TYPE) {
            if (vehicleItem["typeKey"] === typeKey) {
                return vehicleItem["typeValue"];
            }
        }
        return null;
    }

    function overrideTotalWeight(typeKey) {
        const electricInputs = props.electricInputs;
        let orginalVehicleType = convertToVehicleType(typeKey);
        if (orginalVehicleType != null) {
            return orginalVehicleType.replace(/\(.*?\)/g,
                `(${formatNumberDecimals((parseFloat(electricInputs.UnladenWeightOverride) +
                    parseFloat(electricInputs.StaticLoad)) / 1000, 1)}t total)`);
        }
        return null;
    }
    const iceStatistic = props.iceStatistic;
    const evStatistic = props.evStatistic;
    const multiRoutes = props.multiRoutes;
    const iceErrorMsg = props.iceErrorMsg;
    const evErrorMsg = props.evErrorMsg;
    const tooltipTotalTime = (<Tooltip id="informationTooltip" direction="bottom" offset={[0, 0]}>Includes the total driving time and the total service time for all stops</Tooltip>);
    return (
        <Tabs fill className={styles.tabs} defaultActiveKey="general" id="general" onSelect={handleEvOrIceOnClick}>
            <Tab eventKey="general" title={<span className={styles.tabHead}><span className={styles.tabHeadSvg}><ComparisonSvg /></span>Comparison</span>}>
                <p className={`d-print-none ${styles.marginLeft}`}>All costs exclude GST</p>
                <p className={`d-none d-print-inline ${styles.componentTitle}`}>Comparison</p>
                <Table className={styles.outputTable} responsive striped hover>
                    <tbody>
                        <tr>
                            <td className={styles.greyColor}></td>
                            <td><span className={`${styles.cellFont} ${styles.textAlignLeft}`}><IceBigSvg /></span></td>
                            <td><span className={`${styles.cellFont} ${styles.textAlignLeft}`}><ElecBigSvg /></span></td>
                        </tr>
                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Vehicle Type</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>{convertToVehicleType(iceStatistic.VehicleType)}</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>{overrideTotalWeight(evStatistic.VehicleType)}</span></td>
                        </tr>
                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Overall Cost</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(iceStatistic.OveralCost, 2)}</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(evStatistic.OveralCost, 2)}</span></td>
                        </tr>
                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Number of Vehicles Required</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>{iceStatistic.NumberOfVehiclesRequired}</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>{evStatistic.NumberOfVehiclesRequired}</span></td>
                        </tr>
                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Number of Routes</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>{iceStatistic.amountOfRoutes}</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>{evStatistic.amountOfRoutes}</span></td>
                        </tr>

                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Total Distance (km)</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>{formatDistance(iceStatistic.TotalDistance, 2)}</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>{formatDistance(evStatistic.TotalDistance, 2)}</span></td>
                        </tr>
                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Total Energy Used (kWh)</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>N/A</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>{formatNumberDecimals(evStatistic.TotalEnergy, 1)}</span></td>
                        </tr>
                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Energy Economy (kWh/100km)</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>N/A</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>{formatNumberDecimals(((evStatistic.TotalEnergy * 100) / evStatistic.TotalDistance), 1)}</span></td>
                        </tr>
                        <tr>
                            <td className={styles.greyColor}>
                                <span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Total Time (hh:mm)
                                    <OverlayTrigger placement="bottom" overlay={tooltipTotalTime} delayHide={150}>
                                        <sup> <i className={"d-print-none ms-1 bi bi-question-circle-fill"} /></sup>
                                    </OverlayTrigger>
                                </span>
                            </td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>{formatTime(iceStatistic.TotalDriveTime + iceStatistic.TotalServiceTime)}</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>{formatTime(evStatistic.TotalDriveTime + evStatistic.TotalServiceTime)}</span></td>
                        </tr>
                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Total Maintenance Cost</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(iceStatistic.TotalMaintenanceCost, 2)}</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(evStatistic.TotalMaintenanceCost, 2)}</span></td>
                        </tr>
                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Total RUC</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(iceStatistic.TotalRUC, 2)}</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(evStatistic.TotalRUC, 2)}</span></td>
                        </tr>
                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Total Vehicle Crew Cost</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(iceStatistic.TotalVehicleCrewCost, 2)}</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(evStatistic.TotalVehicleCrewCost, 2)}</span></td>
                        </tr>
                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Total Fuel Cost</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(iceStatistic.TotalFuelCost, 2)}</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(evStatistic.TotalFuelCost, 2)}</span></td>
                        </tr>
                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Fuel Cost per km</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(iceStatistic.TotalFuelCostPerKm, 2)}</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(evStatistic.TotalFuelCostPerKm, 2)}</span></td>
                        </tr>

                        <tr>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>Total Cost per km</span></td>
                            <td className={styles.greyColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(iceStatistic.TotalOperatingCostPerkm, 2)}</span></td>
                            <td className={styles.yellowColor}><span className={`${styles.cellFont} ${styles.textAlignLeft}`}>${formatNumberDecimals(evStatistic.TotalOperatingCostPerkm, 2)}</span></td>
                        </tr>
                    </tbody>
                </Table>

            </Tab>
            <Tab eventKey="internal_combustion" title={<span className={styles.tabHead}><span className={styles.tabHeadSvg}><IceSvg /></span>Internal Combustion</span>} >
                {iceErrorMsg != null && iceErrorMsg.length > 0 ? <p className={styles.redColor}>{iceErrorMsg}</p> : null}
                <Form.Group className={`mb-3 ${styles.textCenter}`}>
                    <Button variant="primary" onClick={() => handleTabRoutesOnClick(INTERNAL_COMBUSTION, -1)}>View All Routes</Button>
                </Form.Group>

                <Table className={styles.outputTable} responsive striped hover>
                    <tbody>
                        <tr>
                            <td></td>
                            <td><span className={styles.cellFont}>Time (hh:mm)</span></td>
                            <td><span className={styles.cellFont}>Distance (Km)</span></td>
                        </tr>
                        {multiRoutes.map((route, id) => {
                            return (
                                <tr key={id} id={id} style={{ color: COLOR_LIST[id] }}>
                                    <td><span className={styles.cellFont}>Route {id + 1}</span><Button className={styles.routeButtonSize} variant="primary" size="sm" style={{ backgroundColor: COLOR_LIST[id] }}
                                        onClick={() => handleTabRoutesOnClick(INTERNAL_COMBUSTION, id)}>View Route</Button></td>
                                    <td><span className={styles.cellFont}>{formatTime(route.DriveTime + route.ServiceTime)}</span></td>
                                    <td><span className={styles.cellFont}>{formatDistance(route.Distance, 2)}</span></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Tab>
            <Tab eventKey="electric" title={<span className={styles.tabHead}> <span className={styles.tabHeadSvg}><ElecSvg /></span>Electric</span>}>
                {evErrorMsg != null && evErrorMsg.length > 0 ? <p className={styles.redColor}>{evErrorMsg}</p> : null}
                <Form.Group className={`mb-3 ${styles.textCenter}`}>
                    <Button variant="primary" onClick={() => handleTabRoutesOnClick(ELECTRIC, -1)}>View All Routes</Button>
                </Form.Group>
                <Table className={styles.outputTable} responsive striped hover>
                    <tbody>
                        <tr>
                            <td></td>
                            <td><span className={styles.cellFont}>Time (hh:mm)</span></td>
                            <td><span className={styles.cellFont}>Distance (Km)</span></td>
                            <td><span className={styles.cellFont}>Energy Used (kWh)</span></td>
                        </tr>
                        {multiRoutes.map((route, id) => {
                            return (
                                <tr key={id} id={id} style={{ color: COLOR_LIST[id] }}>
                                    <td><span className={styles.cellFont}>Route {id + 1}</span><Button className={styles.routeButtonSize} variant="primary" size="sm" style={{ backgroundColor: COLOR_LIST[id] }}
                                        onClick={() => handleTabRoutesOnClick(ELECTRIC, id)}>View Route</Button></td>
                                    <td><span className={styles.cellFont}>{formatTime(route.DriveTime + route.ServiceTime)}</span></td>
                                    <td><span className={styles.cellFont}>{formatDistance(route.Distance, 2)}</span></td>
                                    <td><span className={styles.cellFont}>{formatNumberDecimals(route.Energy, 2)}</span></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Tab>
        </Tabs >
    )
}