import React, { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import styles from "./AddressListError.module.css";

export default function AddressListError(props) {
    const divRefAddressError = useRef(null);

    useEffect(() => {
        divRefAddressError.current.scrollIntoView();
    }, []);

    const addressErrorClean = props.addressListError?.filter(addressError => addressError !== undefined);
    return (
        <div ref={divRefAddressError}>
            <Form>
                <Form.Label id={styles.addressNotFoundControlLabel}>
                    The following address{addressErrorClean.lenght > 0 ? "" : "es"} do not exist. Please check input.
                </Form.Label>
                <br />
                {addressErrorClean.map(
                    (addressError, i) => {
                        return (
                            <div key={"addressErrorDiv-" + i}>
                                <Form.Label key={'addressError-' + i}>{i + 1 + " - " + addressError.InputAddress}</Form.Label>
                                <br />
                            </div>)
                    }
                )}
            </Form>
        </div>
    );

}