import React, { useState } from "react";
import { Tooltip, OverlayTrigger, Image, Nav, Navbar } from "react-bootstrap";
import Critchlow_Logo_h64 from '../assets/logos/CRTGEO_logo_h64.png';
import Critchlow_Logo_Square64 from '../assets/logos/critchlow_logo_64x64.png';
import SwitchMyFleetLogo from '../assets/logos/SMF_logo.png';
import AccessTutorial from '../assets/icons/nav/Tutorial.svg';
import Feedback from '../assets/icons/nav/Feedback.svg';
import styles from "./Header.module.css";

export default function Header(props) {

    const [navExpanded, setNavExpanded] = useState(false)
    function handleLogout() {
        props.handleLogout();
        props.setRunAccessTutorial(false);
        setNavExpanded(false);
    }
    function handleAccessTutorial() {
        props.setRunAccessTutorial(true);
        setNavExpanded(false);
    }

    const tooltipAccessTutorial = (<Tooltip id="accessTutorialTooltip" direction="bottom">Access Tutorial</Tooltip>);
    const tooltipFeedback = (<Tooltip id="feedbackTooltip" direction="bottom">Provide Feedback or <br /> Request Support</Tooltip>);
    const tooltipInformation = (<Tooltip id="informationTooltip" direction="bottom">Information</Tooltip>);
    const tooltipUserGuide = (<Tooltip id="userGuiddeTooltip" direction="bottom">User Guide</Tooltip>);

    return (
        <Navbar expanded={navExpanded} expand="lg" className={styles.navbarCustom}>
            <Navbar.Brand>
                <a className={styles.CritchlowLogo} href="https://www.critchlow.co.nz/" target="_blank" rel="noopener noreferrer">
                    <img src={Critchlow_Logo_Square64} width="32" height="32" className="d-sm-none d-lg-none d-inline-block" alt="Critchlow Geospatial Logo" />
                    <img src={Critchlow_Logo_h64} width="152" height="32" className="d-none d-sm-block d-inline-block" alt="Critchlow Geospatial Logo" />
                </a>
                <img src={SwitchMyFleetLogo} width="234" height="50" className={`${styles.SwitchMyFleetLogo} d-inline-block`} alt="Switch My Fleet" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-nav" onClick={() => setNavExpanded(navExpanded ? false : "expanded")} />
            <Navbar.Collapse id="navbar-nav" className={`${styles.navCollapse} justify-content-end`}>
                <Nav className={styles.navCustom} activeKey={props.isAuthenticated ? "/logout" : "/login"}>
                    {props.isAuthenticated ?
                        <OverlayTrigger placement="bottom" overlay={tooltipAccessTutorial} delayHide={0}>
                            <Nav.Link onClick={handleAccessTutorial} aria-label="accessTutorial">
                                <Image id="accessTutorial" src={AccessTutorial} alt="accessTutorial" rounded className={styles.imageSize26} />
                            </Nav.Link>
                        </OverlayTrigger> : null}
                    <OverlayTrigger placement="bottom" overlay={tooltipUserGuide} delayHide={0}>
                        <Nav.Link onClick={() => setNavExpanded(false)} href="/SwitchMyFleet User Guide.pdf" target="_blank" aria-label="userGuide">
                            <i id="userGuide" className={`bi bi-question-circle ${styles.iconFont}`} />
                        </Nav.Link>
                    </OverlayTrigger>

                    <OverlayTrigger placement="bottom" overlay={tooltipFeedback} delayHide={0}>
                        <Nav.Link onClick={() => setNavExpanded(false)} href="https://www.critchlow.co.nz/support?critchlow_product_service=SwitchMyFleet"
                            target="_blank" rel="noopener noreferrer" aria-label="feedback">
                            <Image id="feedback" src={Feedback} alt="feedback" rounded className={styles.imageSize32} />
                        </Nav.Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={tooltipInformation} delayHide={0}>
                        <Nav.Link onClick={() => setNavExpanded(false)} href="/TermsOfService.pdf" target="_blank" aria-label="terms of service">
                            <i id="information" className={`bi bi-info-circle ${styles.iconFont}`} />
                        </Nav.Link>
                    </OverlayTrigger>
                    {props.isAuthenticated
                        ? <Nav.Link onClick={handleLogout} href="/logout" aria-label="logout">
                            Logout<i id="logout" className={`ms-2 bi bi-box-arrow-right ${styles.iconFont}`} />
                        </Nav.Link>
                        :
                        <Nav.Link onClick={() => setNavExpanded(false)} href="/login" aria-label="login">
                            <i className={`me-2 bi bi-box-arrow-in-right ${styles.iconFont}`} />Login
                        </Nav.Link>
                    }
                </Nav>
            </Navbar.Collapse>

        </Navbar >
    );
}
