import React from "react";
import { Alert } from "react-bootstrap";
import styles from "./NotFound.module.css";

export default function NotFound() {
  return (
    <div className={styles.notFound}>
      <Alert variant="danger">
        <Alert.Heading as="h3">Sorry, page not found!</Alert.Heading>
      </Alert>
    </div>
  );
}