import React, { useEffect, useRef } from "react";
import { Button, DropdownButton, Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./UnmatchAddress.module.css";

export default function UnmatchAddress(props) {
    const [geocodedStartAddress, setGeocodedStartAddress] = React.useState(props.unmatchedStartAddress?.length > 0 ? props.unmatchedStartAddress[0] : props.geocodedStartAddress);
    const [geocodedEndAddress, setGeocodedEndAddress] = React.useState(props.unmatchedEndAddress?.length > 0 ? props.unmatchedEndAddress[0] : props.geocodedEndAddress);
    const [selectedItem, setSelectedItem] = React.useState(props.unmatchedList.map((unmatchedAddresses) => { return { ...unmatchedAddresses.address[0], order: unmatchedAddresses.order } }));
    const divRefUnmatchAddress = useRef(null);

    useEffect(() => {
        divRefUnmatchAddress.current.scrollIntoView();
    }, []);

    function OnSelectAddress(eventKey) {
        // console.log("OnChangeAddress: "+ eventKey);
        const eventKeySplit = eventKey.split('|');
        if (eventKeySplit[0] === 'start') {
            setGeocodedStartAddress(props.unmatchedStartAddress[eventKeySplit[1]]);
        } else {
            if (eventKeySplit[0] === 'end') {
                setGeocodedEndAddress(props.unmatchedEndAddress[eventKeySplit[1]]);
            } else {
                const newList = selectedItem;
                newList[eventKeySplit[0]] = props.unmatchedList[eventKeySplit[0]].address[eventKeySplit[1]];
                newList[eventKeySplit[0]].order = props.unmatchedList[eventKeySplit[0]].order;
                setSelectedItem([...newList]);
            }
        }
    }

    function SubmitMatchAddress() {
        let geocodedMatchAddresses = [];
        for (let index = 0; index < selectedItem.length; index++) {
            geocodedMatchAddresses.push(selectedItem[index]);
        }
        props.submitMatchAddressCallBack(geocodedStartAddress, geocodedEndAddress, geocodedMatchAddresses);
    }

    const numberOfUnmatchedAddress = props.unmatchedStartAddress?.length + props.unmatchedList?.length + props.unmatchedEndAddress?.length;
    const tooltipConfirmAddresses = (<Tooltip id={"ttConfirmAddresses"}>Click here to confirm the correct addresses.</Tooltip>);

    return (
        <div id="unmatchedAddress">
            {props.unmatchedStartAddress?.length > 0 ?
                <Form.Group className="mb-3" id={"unmatchDiv-start"}>
                    <Form.Label>{`Start - ${props.unmatchedStartAddress[0].InputAddress}`}</Form.Label>
                        <DropdownButton id={styles.dropdownBasicButton} title={geocodedStartAddress.Address} onSelect={OnSelectAddress} >
                            {props.unmatchedStartAddress.map(
                                (address, j) => {
                                    return <Dropdown.Item key={j} eventKey={'start|' + j} >{address.Address}</Dropdown.Item>
                                }
                            )}
                        </DropdownButton>
                </Form.Group>
                : null
            }
            {props.unmatchedList?.length > 0 ?
                props.unmatchedList?.map(
                    (unmatchedAddress, i) => {
                        return (
                            <Form.Group className="mb-3" key={"unmatchDiv-" + i} id={"unmatchDiv-" + i}>
                                <Form.Label>{`${i + 1} - ${unmatchedAddress.address[0].InputAddress}`}</Form.Label>
                                <DropdownButton id={styles.dropdownBasicButton} title={selectedItem[i].Address} onSelect={OnSelectAddress} >
                                    {unmatchedAddress.address.slice(0, 20).map(
                                        (address, j) => {
                                            return <Dropdown.Item key={j} eventKey={i + '|' + j} >{address.Address}</Dropdown.Item>
                                        }
                                    )}
                                </DropdownButton>
                            </Form.Group>
                        )
                    }
                )
                : null
            }
            {props.unmatchedEndAddress?.length > 0 ?
                <Form.Group className="mb-3" id={"unmatchDiv-end"}>
                    <Form.Label>{`End - ${props.unmatchedEndAddress[0].InputAddress}`}</Form.Label>
                    <DropdownButton id={styles.dropdownBasicButton} title={geocodedEndAddress.Address} onSelect={OnSelectAddress} >
                        {props.unmatchedEndAddress.map(
                            (address, j) => {
                                return <Dropdown.Item key={j} eventKey={'end|' + j} >{address.Address}</Dropdown.Item>
                            }
                        )}
                    </DropdownButton>
                </Form.Group >
                : null
            }

            <div ref={divRefUnmatchAddress}>
                <Form.Group className={styles.textCenter}>
                    <OverlayTrigger placement="top" overlay={tooltipConfirmAddresses} delayHide={150}>
                        <Button variant="primary" id="submitUnmatchAddress" onClick={SubmitMatchAddress}>Confirm Address{numberOfUnmatchedAddress > 1 ? "es" : ""}</Button>
                    </OverlayTrigger>
                </Form.Group>
            </div>
        </div>);

}