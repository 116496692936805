import React from "react";
import { Table } from "react-bootstrap";

import { ReactComponent as CogSvg } from "../assets/icons/tabs/cog.svg";
import { ReactComponent as IceSvg } from "../assets/icons/tabs/ice.svg";
import { ReactComponent as ElecSvg } from "../assets/icons/tabs/elec_yellow.svg";
import { RUN_TYPE, VEHICLE_TYPE } from "../AppConfig";

import styles from "./InputReport.module.css";

export default function InputReport({ generalInputs, iceInputs, electricInputs }) {
    function convertToVehicleType(typeKey) {
        for (const vehicleItem of VEHICLE_TYPE) {
            if (vehicleItem["typeKey"] === typeKey) {
                return vehicleItem["typeValue"];
            }
        }
        return null;
    }
    function convertToRunType(typeKey) {
        return RUN_TYPE[typeKey];
    }
    return (
        <div>
            <p className={`hidden printshowBlock ${styles.reportTitle}`}>REPORT: INPUTS AND COMPARISON</p>
            <p className={`hidden printshow ${styles.fontItalic}`}>All costs exclude GST</p>
            <p className={`hidden printshowBlock ${styles.componentTitle}`}>Inputs</p>
            <Table className="hidden printshowTable" responsive>
                <tbody>
                    <tr>
                        <td className={styles.borderTopNone} colSpan="2"><span className={styles.cellSubTitle}><CogSvg />General</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Run Type</span></td>
                        <td><span className={styles.cellFont}>{convertToRunType(generalInputs.RunType)}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Departure Time</span></td>
                        <td><span className={styles.cellFont}>{new Date(generalInputs.StartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Latest Delivery/Pickup</span></td>
                        <td><span className={styles.cellFont}>{new Date(generalInputs.EndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Service Time (min)</span></td>
                        <td><span className={styles.cellFont}>{generalInputs.ServiceTime}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Average volume per pickup/delivery (m<sup>3</sup>)</span></td>
                        <td><span className={styles.cellFont}>{parseFloat(generalInputs.AverageVolume).toLocaleString()}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Average weight per pickup/delivery (kg)</span></td>
                        <td><span className={styles.cellFont}>{parseFloat(generalInputs.AverageWeight).toLocaleString()}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Vehicle Crew cost per vehicle per hour</span></td>
                        <td><span className={styles.cellFont}>${generalInputs.CrewCost}</span></td>
                    </tr>
                    <tr>
                        <td colSpan="2"><span className={styles.cellSubTitle}><IceSvg />Internal Combustion</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Vehicle Type</span></td>
                        <td><span className={styles.cellFont}>{convertToVehicleType(iceInputs.VehicleType)}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Existing number of Vehicles</span></td>
                        <td><span className={styles.cellFont}>{iceInputs.NumberOfVehicles}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Maximum volume capacity of vehicle (m<sup>3</sup>)</span></td>
                        <td><span className={styles.cellFont}>{parseFloat(iceInputs.MaxVolume).toLocaleString()}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Maximum weight capacity of vehicle (kg)</span></td>
                        <td><span className={styles.cellFont}>{parseFloat(iceInputs.MaxWeight).toLocaleString()}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Current fuel cost per km</span></td>
                        <td><span className={styles.cellFont}>${iceInputs.FuelCostPerKm}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Current maintenance cost per 1,000 km</span></td>
                        <td><span className={styles.cellFont}>${iceInputs.MaintenanceCostPerKm}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Current RUC cost per 1,000 km</span></td>
                        <td><span className={styles.cellFont}>${iceInputs.RucCostPerkm}</span></td>
                    </tr>
                    <tr>
                        <td colSpan="2"><span className={styles.cellSubTitle}><ElecSvg />Electric</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Vehicle Type</span></td>
                        <td><span className={styles.cellFont}>{convertToVehicleType(electricInputs.VehicleType)}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Vehicle Weight</span></td>
                        <td><span className={styles.cellFont}>{parseFloat(electricInputs.UnladenWeightOverride).toLocaleString()}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Static Load (driver, passengers &amp; gear)</span></td>
                        <td><span className={styles.cellFont}>{parseFloat(electricInputs.StaticLoad).toLocaleString()}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Maximum number of Vehicles</span></td>
                        <td><span className={styles.cellFont}>{electricInputs.NumberOfVehicles}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Battery Capacity (kWh)</span></td>
                        <td><span className={styles.cellFont}>{electricInputs.BatteryCapacity}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Maximum volume capacity of vehicle (m<sup>3</sup>)</span></td>
                        <td><span className={styles.cellFont}>{electricInputs.MaxVolume}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Maximum weight capacity of vehicle (kg)</span></td>
                        <td><span className={styles.cellFont}>{parseFloat(electricInputs.MaxWeight).toLocaleString()}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Current Electricity Price per kWh</span></td>
                        <td><span className={styles.cellFont}>${electricInputs.ElectricityPrice}</span></td>
                    </tr>
                    <tr>
                        <td><span className={styles.cellFont}>Current RUC cost per 1,000 km</span></td>
                        <td><span className={styles.cellFont}>${electricInputs.RucCostPerkm}</span></td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}