import React, { useEffect, useState } from "react";
import { MapContainer, AttributionControl } from "react-leaflet";
import WMTSTileLayer from "../components/WMTSTileLayer";
import L from "leaflet";
import Switch from "react-switch";
import LogoInMap from "../components/LogoInMap";
import EVChargers from "../components/EVChargers";
import Legend from "../components/Legend";
import Control from 'react-leaflet-custom-control';
import "leaflet/dist/leaflet.css";
import styles from "./MapWrapper.module.css";
import { MAP_URL } from "../AppConfig";

export default function MapWrapper(props) {
    const mapNationalmap_url = MAP_URL;
    const [map, setMap] = useState(null);
    const maxNorthWest = L.latLng(-33, 165);
    const maxSouthEast = L.latLng(-48, 180);
    const mapCenter = [-41, 173];
    const [showIcons, setShowIcons] = React.useState(false);
    const [currentZoom, setCurrentZoom] = React.useState(7);
    function toggleIcons() {
        setShowIcons(!showIcons);
    }
    useEffect(
        () => {
            if (!map) return;
            map.fitBounds(props.mapBounds);
            map.on("zoomend", function () {
                setCurrentZoom(map.getZoom());
            });
        }, [map, props.mapBounds]
    );

    return (
        <MapContainer id="mapDiv" ref={setMap}
            maxZoom={18} minZoom={7} doubleClickZoom={false}
            zoom={7}
            maxBounds={L.latLngBounds(maxNorthWest, maxSouthEast)} center={mapCenter}
            preferCanvas={true}
            attributionControl={false}>
            <Control position="topleft" style={{ border: 0 }}>
                {(props.multiRoutes.length > 0 || props.addressesNotAssigned.length > 0) ? <LogoInMap currentTab={props.currentTab} /> : null}
            </Control>
            <Control position="topright" style={{ border: 0 }}>
                <label id="switch-chargers-label" htmlFor="switch-chargers" className={styles.switchLabel}>
                    <span className="printhide">EVRoam Chargers </span>
                    <Switch id="switch-chargers" onChange={toggleIcons} checked={showIcons} className={styles.switchButton}
                        uncheckedIcon={false} checkedIcon={false} onColor="#4CD862" offColor="#C4C4C4" />
                </label>
                {currentZoom > 9 ? <Legend /> : null}
            </Control>
            <WMTSTileLayer
                url={mapNationalmap_url}
                layer="NationalMap:NationalMap SwitchMyFleet Basemap"
                tilematrixSet="EPSG:900913"
                format="image/png"
                maxZoom={18}
                attribution={'Basemap <a href="http://nationalmap.co.nz/" target="_blank" title="" rel="noopener noreferrer">&copy; NationalMap™</a> | Charger Data <a href="https://www.nzta.govt.nz/planning-and-investment/planning/transport-planning/planning-for-electric-vehicles/evroam" target="_blank" title="" rel="noopener noreferrer">EVRoam</a>'}
            />
            <AttributionControl position='bottomright' prefix={false} />
            {showIcons ? <EVChargers /> : null}
            {props.geocodedListMarker}
            {props.addressesNotAssignedMarker}
            {props.polyline}
        </MapContainer >
    )
}