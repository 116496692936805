import React from "react";
import ReactToPrint from 'react-to-print';
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./PrintButtonArea.module.css";

export default function PrintButtonArea(props) {

    const contentInputAndComparison = React.useCallback(() => {
        return props.componentInputAndComparisonToPrint.current;
      }, [props.componentInputAndComparisonToPrint]);

    const contentMap = React.useCallback(() => {
        return props.componentMapToPrint.current;
      }, [props.componentMapToPrint]);

    const handleBeforePrintMap = React.useCallback(() => {
        const mapHeight = props.mapHeight;
        document.title = "SwitchMyFleet Map";
        document.getElementById("mapDiv").style.height = mapHeight + "px";
        if (mapHeight < 500) {
            return;
        } else if (mapHeight < 680) {
            document.getElementById("printDivMap").classList.add("zoom90");
        } else if (mapHeight < 720) {
            document.getElementById("printDivMap").classList.add("zoom85");
        } else if (mapHeight < 800) {
            document.getElementById("printDivMap").classList.add("zoom80");
        } else if (mapHeight < 850) {
            document.getElementById("printDivMap").classList.add("zoom75");
        } else if (mapHeight < 940) {
            document.getElementById("printDivMap").classList.add("zoom70");
        } else if (mapHeight < 1000) {
            document.getElementById("printDivMap").classList.add("zoom65");
        } else if (mapHeight < 1100) {
            document.getElementById("printDivMap").classList.add("zoom60");
        } else if (mapHeight < 1200) {
            document.getElementById("printDivMap").classList.add("zoom55");
        } else if (mapHeight < 1300) {
            document.getElementById("printDivMap").classList.add("zoom50");
        } else if (mapHeight < 1500) {
            document.getElementById("printDivMap").classList.add("zoom45");
        } else if (mapHeight < 1700) {
            document.getElementById("printDivMap").classList.add("zoom40");
        } else if (mapHeight < 2000) {
            document.getElementById("printDivMap").classList.add("zoom35");
        } else if (mapHeight < 2400) {
            document.getElementById("printDivMap").classList.add("zoom30");
        } else if (mapHeight < 2800) {
            document.getElementById("printDivMap").classList.add("zoom25");
        } else if (mapHeight < 3500) {
            document.getElementById("printDivMap").classList.add("zoom20");
        } else if (mapHeight < 4500) {
            document.getElementById("printDivMap").classList.add("zoom15");
        } else {
            document.getElementById("printDivMap").classList.add("zoom10");
        }
    }, [props.mapHeight]);

    const handleAfterPrintMap = React.useCallback(() => {
        document.getElementById("mapDiv").style.height = "";
        document.title = "SwitchMyFleet";
        document.getElementById("printDivMap").classList.remove("zoom95");
        document.getElementById("printDivMap").classList.remove("zoom90");
        document.getElementById("printDivMap").classList.remove("zoom85");
        document.getElementById("printDivMap").classList.remove("zoom80");
        document.getElementById("printDivMap").classList.remove("zoom75");
        document.getElementById("printDivMap").classList.remove("zoom70");
        document.getElementById("printDivMap").classList.remove("zoom65");
        document.getElementById("printDivMap").classList.remove("zoom60");
        document.getElementById("printDivMap").classList.remove("zoom55");
        document.getElementById("printDivMap").classList.remove("zoom50");
        document.getElementById("printDivMap").classList.remove("zoom45");
        document.getElementById("printDivMap").classList.remove("zoom40");
        document.getElementById("printDivMap").classList.remove("zoom35");
        document.getElementById("printDivMap").classList.remove("zoom30");
        document.getElementById("printDivMap").classList.remove("zoom25");
        document.getElementById("printDivMap").classList.remove("zoom20");
        document.getElementById("printDivMap").classList.remove("zoom15");
        document.getElementById("printDivMap").classList.remove("zoom10");
    }, []);


    return (
        <div className={`${styles.textCenter} mb-3`}>
            <ReactToPrint className="printhide"
                trigger={() => <Button variant="primary" className="printhide me-2">Print Inputs and Comparison</Button>}
                content={contentInputAndComparison}
                documentTitle="SwitchMyFleet Report: Inputs and Comparison"
            />
            <ReactToPrint className="printhide"
                trigger={() =>
                    <Button variant="primary" className="printhide">
                        <span>Print Map
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="infoPrintMap">Tip: Before you "Print Map", zoom in/out to show the full extent of your route(s).</Tooltip>}
                                delayHide={150}>
                                <sup><i className={"ms-1 bi bi-question-circle-fill"} /></sup>
                            </OverlayTrigger>
                        </span>
                    </Button >}
                content={contentMap}
                onBeforeGetContent={handleBeforePrintMap}
                onAfterPrint={handleAfterPrintMap}
                documentTitle="SwitchMyFleet Report: Map"
            />
        </div >
    )
}