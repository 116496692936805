import L from "leaflet";

export default function MarkerIcon(fillColor, strokeColor, centreColor) {
    const svgTemplate = `
    <svg width="22" height="32" viewBox="0 0 22 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.999 31.1901C10.9747 31.1587 10.9493 31.1259 10.9229 31.0918C10.6244 30.7057 10.1972 30.1443 9.6844 29.4452C8.65833 28.0465 7.291 26.0991 5.92453 23.9029C4.55716 21.7052 3.1962 19.2672 2.17851 16.8864C1.1571 14.4969 0.5 12.207 0.5 10.2935C0.5 4.92992 5.18471 0.5 11 0.5C16.8177 0.5 21.5 4.896 21.5 10.2573C21.5 12.1617 20.843 14.4492 19.8214 16.8408C18.8037 19.2235 17.4426 21.6673 16.0751 23.8719C14.7085 26.075 13.3411 28.0304 12.315 29.4353C11.8021 30.1376 11.3749 30.7017 11.0764 31.0897C11.0495 31.1246 11.0237 31.1581 10.999 31.1901ZM4.32456 10.149C4.32456 13.6342 7.35915 16.4278 11 16.4278C14.6408 16.4278 17.6754 13.6342 17.6754 10.149C17.6754 6.66376 14.6408 3.8702 11 3.8702C7.35915 3.8702 4.32456 6.66376 4.32456 10.149Z" 
    fill="${fillColor}" 
    stroke="${strokeColor}"
    stroke-width="2"/>
    <path d="M11 3.5C6.87386 3.5 3.5 6.87386 3.5 11C3.5 15.1261 6.87386 18.5 11 18.5C15.1261 18.5 18.5 15.1261 18.5 11C18.5 6.82153 15.1238 3.5 11 3.5Z" 
    fill="${centreColor}" 
    stroke="${strokeColor}"
    stroke-width="0"/>
    </svg>
    `;

    return new L.DivIcon({
        className: 'marker-icon',
        html: svgTemplate,
        iconSize: [22, 36],
        iconAnchor: [9, 34],
        popupAnchor: [7, -16],
    })
}