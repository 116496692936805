import React from "react";
import { ReactComponent as FordSvg } from '../assets/icons/others/ford.svg';
import styles from "./Legend.module.css";

export default function Legend() {

    return (
        <div className={`${styles.container} mt-1`}>
            <span className={styles.leftFont}>River Ford</span>
            <FordSvg className={styles.rightImage} />
        </div>
    );
}
