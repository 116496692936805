import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { AppContext } from "./libs/contextLib";
import MainRoutes from "./MainRoutes";
import Header from "./components/Header";
import classNames from "classnames";
import styles from "./App.module.css";

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [runAccessTutorial, setRunAccessTutorial] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      const currentUserInfo = await Auth.currentUserInfo();
      const company = currentUserInfo.attributes["custom:company"];
      const user = currentUserInfo.attributes["email"];
      const loginCount = currentUserInfo.attributes["custom:login_count"];
      console.log("company: ", company);
      console.log("user: ", user);
      console.log("login count: " + loginCount);
    } catch (e: any) {
      console.log("error fetching user info: ", e);
      if (e !== "No current user") {
        alert(e.message);
      }
    }
  }

  function handleLogout() {
    Auth.signOut();
    userHasAuthenticated(false);
    navigate("/login");
  }

  return (
    <div className={classNames(styles.main)}>
      <Header
        isAuthenticated={isAuthenticated}
        handleLogout={handleLogout}
        setRunAccessTutorial={setRunAccessTutorial}
      />
      <AppContext.Provider
        value={{
          isAuthenticated,
          userHasAuthenticated,
          runAccessTutorial,
          setRunAccessTutorial,
        }}
      >
        <MainRoutes />
      </AppContext.Provider>
    </div>
  );
}
export default App;
