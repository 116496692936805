import React from "react";
import { TileLayer } from "react-leaflet";
import { MAP_URL } from "../AppConfig";
const WMTSTileLayer = ({ url, layer, format, tilematrixSet, attribution, maxZoom }) => {
  const params = '?service=WMTS&request=GetTile&version=1.0.0&tilematrixSet=' + tilematrixSet +
    '&format=' + format +
    '&height=256&width=256&layer=' + layer +
    '&leaflet=%5Bobject%20Object%5D&tilematrix=' + tilematrixSet +
    ':{z}&tilerow={y}&tilecol={x}';
  return (
    <TileLayer url={(url ?? MAP_URL) + params} attribution={attribution} maxZoom={maxZoom} />
  )
};
export default WMTSTileLayer;


