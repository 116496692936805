// import { ReactComponent as PolygonSvg } from "../assets/images/polygon.svg";
import styles from "./Ribbon.module.css";

const Ribbon = () => {
    return (
        process.env.REACT_APP_ENV !== 'prod' ?
            <div data-cy="ribbon" className={styles.cornerRibbon}>Test: {process.env.REACT_APP_ENV}|{process.env.REACT_APP_API_KEY}</div>
            : null
    );
};
export default Ribbon;

