import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { API_GATEWAY, COGNITO, SMART_ROUTING_EV } from "./AppConfig";
import "./style.scss";
import "./common.css";
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: COGNITO.REGION,
    userPoolId: COGNITO.USER_POOL_ID,
    userPoolWebClientId: COGNITO.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: SMART_ROUTING_EV,
        endpoint: API_GATEWAY.URL,
        region: API_GATEWAY.REGION,
        custom_header: async () => {
          return {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          };
        },
      },
    ],
  },
});
Amplify.Logger.LOG_LEVEL = "DEBUG";
const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);
