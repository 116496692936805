export const NUMBER_ADDRESS_LIMIT = 100; 
const status = {
    normal: true,
    error: false
}
//==================================================================
export const SYSTEM_STATUS_SWITCH = status.normal;
//==================================================================
export const COLOR_LIST = ["#00b7ee", "#ffc322", "#c3d021", "#008709", "#56004e", "#008c6c", "#0d3589", "#5d2e9e", "#444444", "#e50278"];
export const INTERNAL_COMBUSTION = 'internal_combustion';
export const ELECTRIC = 'electric';
export const SMART_ROUTING_EV = "smart-routing-ev";
export const IQOFFICE_VALIDATE = '/iqoffice-validate';

export const MAP_URL = "https://maps.nationalmap.co.nz/proxy/wmts";

export const API_GATEWAY = {
    REGION: "ap-southeast-2",
    URL: "https://uj9sgb4k2h.execute-api.ap-southeast-2.amazonaws.com/prod"
}
export const COGNITO = {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_rl95XOwIP", //smart-routing-ev-pool
    APP_CLIENT_ID: "3jtfqdmpvvvu78v9kq4ju3hcbl", //smart-routing-ev APP Client
}
export const DEFAULT_VEHICLE_TYPE = "Van (2.6t unladen)";
export const VEHICLE_TYPE = [
    {
        "typeKey": 3,
        "typeValue": "Car (1.9t unladen)",
        "typeMass": "1880"
    },
    {
        "typeKey": 0,
        "typeValue": DEFAULT_VEHICLE_TYPE,
        "typeMass": "2600"
    },
    {
        "typeKey": 1,
        "typeValue": "Light Truck (4.4t unladen)",
        "typeMass": "4400"
    },
    {
        "typeKey": 2,
        "typeValue": "Medium Truck (8.0t unladen)",
        "typeMass": "8000"
    },
    {
        "typeKey": 4,
        "typeValue": "Heavy Truck (18.0t unladen)",
        "typeMass": "18000"
    }
]
export const RUN_TYPE = [
    "Delivery",
    "Pickup"
]
