import React from 'react';
import { ReactComponent as ElecBigSvg } from "../assets/icons/outputs/elec_big.svg";
import { ReactComponent as IceBigSvg } from "../assets/icons/outputs/ice_big.svg";
import { INTERNAL_COMBUSTION } from "../AppConfig";

export default function LogoInMap(props) {
    const { currentTab } = props;
    let logoInMap;
    if (currentTab === INTERNAL_COMBUSTION) {
        logoInMap = <IceBigSvg />
    } else {
        logoInMap = <ElecBigSvg />
    }
    return (
        <div>{logoInMap}</div>
    )
}