import React from "react";
import MarkerClusterGroup from "@changey/react-leaflet-markercluster";
import EVChargerMarker from '../assets/icons/others/ev_charger.svg';
import ClusterMarker from '../assets/icons/others/ev_charger_cluster.svg'
import { GeoJSON } from "react-leaflet";
import L from "leaflet";

export default function EVChargers() {
    /**
     * make the Markers of EV-chargers
     * @param {*} feature 
     * @param {*} latlng 
     */
    function pointToLayer(feature, latlng) {
        const customIcon = L.icon({
            iconUrl: EVChargerMarker,
            iconSize: [27, 32], // size of the icon
            iconAnchor: [13, 32], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -30]// point from which the popup should open relative to the iconAnchor
        });
        return L.marker(latlng, { icon: customIcon, opacity: 1 }); // Change the icon to a custom icon
    }
    /**
     * Showing the details of each EV charger stations
     * @param {*} feature Features of EV charger stations, include of Name, Address, ChargingCost and ConnectorType
     */
    function getFeatureDetails(feature) {
        let strDetails = "";
        if (feature.properties.name != null) {
            strDetails += "<b>" + feature.properties.name + "</b><br/>";
        }
        if (feature.properties.address != null) {
            strDetails += feature.properties.address + "<br/>";
        }
        if (feature.properties.owner != null) {
            strDetails += feature.properties.owner + "<br/>";
        }
        if (feature.properties.hasChargingCost === "True") {
            strDetails += "Charging costs apply<br/>";
        } else {
            strDetails += "No charging costs<br/>";
        }
        if (feature.properties.connectorsList != null) {
            strDetails += "Connector Types:<br/>";
            var connectors = feature.properties.connectorsList.match(/{(.*?)}/g);
            if (connectors != null) {
                connectors.map((value) => strDetails += value.substring(1, value.length - 1) + '<br/>');
            }
        }
        return strDetails;
    }

    /**
     * make the cluster Markers
     * @param {*} cluster 
     */
    function customIconCreateFunction(cluster) {
        return L.divIcon({
            html: `<img src='${ClusterMarker}' style="position: absolute; top: 0px; left: 0px; height:32px; width:27px;">
      <div style="position: absolute;top: -5px;left: 0px;color: #0C7654;font-weight: bold;
      text-align: center;width: 26px;line-height:34px;">
      ${cluster.getChildCount()}</div>`,
        });
    }

    return (
        <MarkerClusterGroup iconCreateFunction={customIconCreateFunction} showCoverageOnHover={false}>
            <GeoJSON key={Math.random()} data={require('../assets/data/chargers.json')} pointToLayer={pointToLayer} onEachFeature={(feature, layer) => layer.bindPopup(getFeatureDetails(feature))} />
        </MarkerClusterGroup>
    )
}
