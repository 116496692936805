import React from "react";
import { Button, Spinner } from "react-bootstrap";
import styles from "./LoaderButton.module.css";
export default function LoaderButton({
  isLoading,
  text,
  loadingText,
  disabled = false,
  ...props
}) {
  return (
    <Button
      className={styles.loaderButton}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading &&
        <Spinner className={styles.spinning}
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />}
      {!isLoading ? text : loadingText}
      {props.children}
    </Button>
  );
}