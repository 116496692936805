import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Button, Card, Form, Alert, Stack } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import ConfirmationForm from "../containers/ConfirmationForm";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import BackgroundImage from "../assets/login_page.png";
import styles from "./Login.module.css";

export default function Login(props) {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    signuperror: ""

  });
  const [isDisplayConfirmationCode, setDisplayConfirmationCode] = useState(false);

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      userHasAuthenticated(true);
    } catch (e) {
      switch (e.code) {
        case 'UserNotFoundException':
          //return true;
          fields.signuperror = e.message;
          break;
        case 'NotAuthorizedException':
          fields.signuperror = e.message;
          break;
        case 'AliasExistsException':
          // Email alias already exists
          fields.signuperror = e.message;
          break;
        case 'CodeMismatchException':
          fields.signuperror = e.message;
          break;
        case 'ExpiredCodeException':
          fields.signuperror = e.message;
          break;
        case 'UserNotConfirmedException':
          fields.signuperror = e.message;
          handleEnterSignupCodeOnClick(); // go straight to the Confirmation Code form
          break;
        case 'UsernameExistsException':
          fields.signuperror = "An account with the given email already exists. Click Enter Signup code.";
          break;
        default:
          fields.signuperror = e.message;
          break;
      }

      if (e.message === 'Only radix 2, 4, 8, 16, 32 are supported')  // This message would be confusing to users. Known Amazon issue, may eventually get fixed.
        fields.signuperror = 'Incorrect username or password.';

      console.log(e);
      console.log(e.message);

      alert(fields.signuperror);

      setIsLoading(false);
    }
  }

  function handleEnterSignupCodeOnClick() {
    setDisplayConfirmationCode(true);
  }

  function renderLoginError() {
    return (
      <Alert variant="danger">
        <Alert.Heading as="h5">Error logging in</Alert.Heading>
        <p>{fields.signuperror}</p>
      </Alert>
    );
  }

  function renderForm() {
    return (
      <div className={`${styles.login} row gx-1`}>
        <div className={`${styles.loginLeft} col-md-8 order-first d-none d-md-block`} style={{ backgroundImage: `url(${BackgroundImage})` }} />
        <div className={`${styles.loginRight} col-md-4 order-last`}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div id={styles.learnMorePanel}>
              <h4 id={styles.learnMoreTitle}> <i id="information" className="bi bi-exclamation-circle me-2" />BEFORE YOU LOGIN</h4>
              <h6 className={styles.learnMoreTitle}>We suggest you get an overview of SwitchMyFleet before using it.</h6>
              <Button id={styles.learnMoreBtn} onClick={() => { window.open('https://www.critchlow.co.nz/products/switchmyfleet/'); }}>CLICK HERE TO LEARN MORE</Button>
            </div>
            <Card className="text-center" bg="light">
              <Card.Body>
                <Form.Group controlId="email" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control size="lg" className={styles.formControl}
                    autoFocus
                    type="email"
                    value={fields.email}
                    onChange={handleFieldChange}
                  />
                </Form.Group>
                <Form.Group controlId="password" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control size="lg" className={styles.formControl}
                    type="password"
                    value={fields.password}
                    onChange={handleFieldChange}
                  />
                  <Link to="/login/reset">Forgot password?</Link>
                </Form.Group>
                <Stack gap={2} className={`${styles.alignCenter} mx-auto`}>
                  <LoaderButton style={{ width: '100%' }}
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                    variant="warning"
                    size="lg"
                  >
                    Login
                  </LoaderButton>
                  {fields.signuperror.length > 0 ? renderLoginError() : null}
                  <LoaderButton style={{ width: '100%' }}
                    href="/signup/sr4ev4e8-2e97-439f-a59a-d5d0cb5962de"
                    type="button"
                    size="lg"
                    variant="outline-secondary"
                  >
                    Register
                  </LoaderButton>
                </Stack>
              </Card.Body>
            </Card>
          </form>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <>
      {
        isDisplayConfirmationCode ? <ConfirmationForm email={fields.email} password={fields.password} props={props} /> : renderForm()
      }
    </>
  );

}