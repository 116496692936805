import React from "react";
import { Image } from "react-bootstrap";
import IQOfficeLogo from "../assets/logos/IQ_Office_logo.png";
import NationalMapLogo from "../assets/logos/NationalMap_logo.png";
import EECALogo from "../assets/logos/EECA_logo.png";
import styles from "./Footer.module.css";

export default function Footer({ children }) {
    return (
        <div className={styles.container}>
            {children}
            <div className={styles.outDiv}>
                <div className={styles.innerDiv}>
                    <Image id={styles.nationalMapLogo} src={NationalMapLogo} alt="NationalMap" rounded className={styles.images} />
                    <Image id={styles.iqOfficeLogo} src={IQOfficeLogo} alt="IQ Office" rounded className={styles.images} />
                    <Image id={styles.eecaLogo} src={EECALogo} alt="EECA" rounded className={styles.images} />
                </div>
            </div>
        </div>
    )
}