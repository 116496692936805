import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";

export default function DropdownWithItem(props) {
    function handleTypeOnSelect(evtKey, evt) {
        let item = props.data[evtKey];
        props.handleTypeOnSelect(item["typeKey"], evt);
    }
    return (
        <DropdownButton id={props.id} title={props.title} key={props.typeKey} variant="outline-secondary"
            onSelect={handleTypeOnSelect}>
            {props.data.map((item, id) => (
                <Dropdown.Item key={id} eventKey={id}>{item["typeValue"]}</Dropdown.Item>
            ))}
        </DropdownButton>
    );
}