import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useFormFields } from "../libs/hooksLib";
import LoaderButton from "../components/LoaderButton";
import { Form, Alert, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./ConfirmationForm.module.css";
export default function ConfirmationForm(props) {
    const [fields, handleFieldChange] = useFormFields({
        company: "",
        confirmationCode: "",
        EmailConfirmationResend: "",
        CodeConfirmationResend: "",
        signuperror: "",
        confirmusererror: "",
        resendsignuperror: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        fields.confirmusererror = "";
        try {
            await Auth.confirmSignUp(props.email, fields.confirmationCode);
            // jump to success page with a button which points to login page
            setConfirmed(true);
        } catch (e) {
            fields.confirmusererror = e.message;
            setIsLoading(false);
        }
    }

    function renderSuccessMessage() {
        return (
            <div className={styles.success}>
                <i className={`${styles.iconOk} bi bi-check2-circle`} />
                <p>This account is confirmed.</p>
                <LoaderButton
                    href="/login"
                    type="button"
                    size="lg"
                >
                    Back to login screen
                </LoaderButton>
            </div>
        );
    }

    function renderConfirmError() {
        return (
            <Alert variant="danger">
                <Alert.Heading as="h5">Error signing up</Alert.Heading>
                <p>
                    {fields.confirmusererror}
                </p>
            </Alert>
        );
    }
    function validateConfirmationForm() {
        return fields.confirmationCode.length > 0;
    }
    function handleResendCodeClick(e) {
        handleResendSignUpSubmit(e);
    }
    async function handleResendSignUpSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        fields.resendsignuperror = "";
        fields.CodeConfirmationResend = "Confirmation code re-sent. Please check your email for the most recent code.";
        try {
            await Auth.resendSignUp(props.email);
            setIsLoading(false);
        } catch (e) {
            fields.resendsignuperror = e.message;
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    function renderCodeResentConfirmation() {
        return (
            <Alert variant="success">
                <Alert.Heading as="h5">Code re-sent</Alert.Heading>
                <p>{fields.CodeConfirmationResend}</p>
            </Alert>
        );
    }

    return (
        <div className={styles.confirmationForm}>
            {!confirmed ?
                <form onSubmit={handleConfirmationSubmit}>
                    <Card bg="light">
                        <Card.Body>
                            <Form.Group className="mb-3" controlId="confirmationCode">
                                <Form.Label>Confirmation Code</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="tel"
                                    size="lg"
                                    onChange={handleFieldChange}
                                    value={fields.confirmationCode}
                                />
                                <Form.Text muted>Please check your email for the code. If you don't see your confirmation email, please check your Spam or Junk Mail folder.</Form.Text>
                            </Form.Group>

                            {fields.confirmusererror.length > 0 ? renderConfirmError() : null}
                            <div className="d-grid">
                                <LoaderButton
                                    type="submit"
                                    size="lg"
                                    isLoading={isLoading}
                                    disabled={!validateConfirmationForm()}
                                >
                                    Verify
                                </LoaderButton>
                                <Link to="#" id="enterSignupCode" onClick={(e) => { handleResendCodeClick(e) }}>Resend code</Link>
                            </div>

                            {fields.CodeConfirmationResend.length > 0 ? renderCodeResentConfirmation() : null}
                        </Card.Body>
                    </Card>
                </form>
                : renderSuccessMessage()}
        </div>
    );
}

